import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  /*{
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },*/
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./pages/scheduler/scheduler.module').then( m => m.SchedulerPageModule)
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders/my-orders.module').then( m => m.MyOrdersPageModule)
  }/*,
  {
    path: 'assembly-quote',
    pathMatch: 'full',
    loadChildren: () => import('./pages/my-orders/assembly-quote/assembly-quote.module').then( m => m.AssemblyQuotePageModule)
  }*/
  ,
  {
    path: 'create-step1',
    loadChildren: () => import('./pages/my-orders/create-step1/create-step1.module').then( m => m.CreateStep1PageModule)
  },
  {
    path: 'assembly-quote',
    // pathMatch: 'full',
    loadChildren: () => import('./pages/my-orders/assembly-quote/assembly-quote.module').then( m => m.AssemblyQuotePageModule)
  },
  {
    path: 'shipping-info',
    loadChildren: () => import('./pages/my-orders/shipping-info/shipping-info.module').then( m => m.ShippingInfoPageModule)
  },
  {
    path: 'order-review',
    loadChildren: () => import('./pages/my-orders/order-review/order-review.module').then( m => m.OrderReviewPageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./pages/my-orders/payment-success/payment-success.module').then( m => m.PaymentSuccessPageModule)
  },
  {
    path: 'create-new-payment-method',
    loadChildren: () => import('./pages/modals/create-new-payment-method/create-new-payment-method.module').then( m => m.CreateNewPaymentMethodPageModule)
  },
  {
    path: 'create-new-address',
    loadChildren: () => import('./pages/modals/create-new-address/create-new-address.module').then( m => m.CreateNewAddressPageModule)
  },
  {
    path: 'order-validation-success',
    loadChildren: () => import('./pages/my-orders/order-validation-success/order-validation-success.module').then( m => m.OrderValidationSuccessPageModule)
  },
  {
    path: 'create-address-book',
    loadChildren: () => import('./pages/modals/create-address-book/create-address-book.module').then( m => m.CreateAddressBookPageModule)
  },
  {
    path: 'payment-book',
    loadChildren: () => import('./pages/modals/payment-book/payment-book.module').then( m => m.PaymentBookPageModule)
  },
  {
    path: 'address-fields-list',
    loadChildren: () => import('./pages/modals/address-fields-list/address-fields-list.module').then( m => m.AddressFieldsListPageModule)
  },
  {
    path: 'session-timeout-model',
    loadChildren: () => import('./pages/modals/session-timeout-model/session-timeout-model.module').then( m => m.SessionTimeoutModelPageModule)
  },
  {
    path: 'data-lost',
    loadChildren: () => import('./pages/modals/data-lost/data-lost.module').then( m => m.DataLostPageModule)
  },
  {
    path: 'line-item-comparision',
    loadChildren: () => import('./pages/modals/line-item-comparision/line-item-comparision.module').then( m => m.LineItemComparisionPageModule)
  },
  {
    path: 'bom-search-assist',
    loadChildren: () => import('./pages/my-orders/bom-search-assist/bom-search-assist.module').then( m => m.BomSearchAssistPageModule)
  },
  {
    path: 'assembly-modal',
    loadChildren: () => import('./pages/modals/assembly-modal/assembly-modal.module').then( m => m.AssemblyModalPageModule)
  },
  {
    path: 'final-quote',
    loadChildren: () => import('./pages/my-orders/final-quote/final-quote.module').then( m => m.FinalQuotePageModule)
  },
  {
    path: 'log-in',
    loadChildren: () => import('./pages/modals/log-in/log-in.module').then( m => m.LogInPageModule)
  }





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
