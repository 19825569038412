import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import * as $ from 'jquery';
import {CreateNewPaymentMethodPage} from '../../modals/create-new-payment-method/create-new-payment-method.page';
import { RemoteService } from '../../../providers/remote.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-payment-book',
  templateUrl: './payment-book.page.html',
  styleUrls: ['./payment-book.page.scss'],
})
export class PaymentBookPage implements OnInit {
  checkedIdx = -1;
  selectedCreditId = 'no_id'
  checkedCardIdx =-1;
  markAsDefault = false;
  payinfo = [
    { name: 'Maria Rodriguez', default:'yes',  cardtype: 'American Express', cardnumber:'XXXX-XXXX-XXXX8994', expirydate:'12/31' , cvv:'845', id:'1' },
    { name: 'James Smith',  default:'no', cardtype: 'Visa', cardnumber:'XXXX-XXXX-XXXX1234', expirydate:'06/24' , cvv:'121', id:'2' },
    { name: 'Rakesh',  default:'no', cardtype: 'Visa', cardnumber:'XXXX-XXXX-XXXX5412', expirydate:'06/24' , cvv:'123', id:'3' }
    
  ];

  constructor(private modelController:ModalController,
              private remoteService:RemoteService,
              private alertController:AlertController) { }

  ngOnInit() {
    // var card_details = this.remoteService.getCreditCardInfo();
    this.remoteService.creditCardInfo = [{name : this.remoteService.selectedCreditCard.name, default : this.remoteService.selectedCreditCard.defaultcard, 
      cardtype : this.remoteService.selectedCreditCard.cardtype, cardnumber : this.remoteService.selectedCreditCard.cardnumber,
      expirydate : this.remoteService.selectedCreditCard.expirydate, cvv : this.remoteService.selectedCreditCard.cvv,
      id : "1", typeid : "1"
    }];
    
  }
  addNewPaymentMethod(){
    this.modelController.create({component:CreateNewPaymentMethodPage}).then((modalElement)=>{
      modalElement.present();
    });
    
    this.modelController.dismiss();
  }
  closeModal(){
    this.modelController.dismiss();
  }
  // checkboxClick(cardId){
  //   // if(this.checkedIdx == -1){
  //   //   this.selectedCreditId = cardId;
  //   // }else{
  //   //   this.selectedCreditId = 'no_id';
  //   // }
  //   var selectedIndex = -1;
  //   var selectedCardId=cardId;
  //   for (var i = 0; i < this.remoteService.creditCardInfo.length; i++){
  //     this.remoteService.creditCardInfo[i]['isCheckedCard'] = null;
  //     if (this.remoteService.creditCardInfo[i].id == selectedCardId){
  //       selectedIndex = i;
  //       this.selectedCreditId = selectedCardId;
  //       $('#credit_card_'+ this.remoteService.creditCardInfo[i].id).addClass( 'active-box' );
  //       this.remoteService.creditCardInfo[selectedIndex].default = true;
  //     }
  //     else{
  //       $('#credit_card_'+ this.remoteService.creditCardInfo[i].id).removeClass( 'active-box' );
  //       this.remoteService.creditCardInfo[0].default = false;
  //     }
  //   }
  //   if(selectedIndex !=-1){
  //     var temp = this.remoteService.creditCardInfo[0];
  //     this.remoteService.creditCardInfo[0] = this.remoteService.creditCardInfo[selectedIndex];
  //     this.remoteService.creditCardInfo[selectedIndex]= temp;
      
  //   }
  // }
  
  checkboxClick(cardId){
    var selectedIndex = -1;
    var selectedCardId=cardId;
    for (var i = 0; i < this.remoteService.creditCardInfo.length; i++){
      this.remoteService.creditCardInfo[i]['isCheckedCard'] = null;
      if (this.remoteService.creditCardInfo[i].id == selectedCardId){
        //selectedIndex = i;
        this.selectedCreditId = selectedCardId;
        $('#selectPay_'+selectedCardId).hide();
        $('#selcted_lbl_'+selectedCardId).addClass('selectedCard');
        $('#credit_card_'+ this.remoteService.creditCardInfo[i].id).addClass( 'active-box' );
        //this.remoteService.creditCardInfo[selectedIndex].default = true;
      }else{
        $('#credit_card_'+ this.remoteService.creditCardInfo[i].id).removeClass( 'active-box' );
        $('#selectPay_'+this.remoteService.creditCardInfo[i].id).show();
        $('#selcted_lbl_'+this.remoteService.creditCardInfo[i].id).removeClass('selectedCard');
        //this.remoteService.creditCardInfo[0].default = false;
      }
    }
    // if(selectedIndex !=-1){
    //   var temp = this.remoteService.creditCardInfo[0];
    //   this.remoteService.creditCardInfo[0] = this.remoteService.creditCardInfo[selectedIndex];
    //   this.remoteService.creditCardInfo[selectedIndex]= temp;
      
    // }
  }
  selectCreditCard(){
    if (this.selectedCreditId == 'no_id'){
      alert('Please select a credit card');
    }else{
      console.log(this.remoteService.creditCardInfo);
      for (var i = 0; i < this.remoteService.creditCardInfo.length; i++){
        if (this.remoteService.creditCardInfo[i].id == this.selectedCreditId){
          //console.log(this.remoteService.creditCardInfo[i])
          if(this.markAsDefault){
            this.remoteService.cardType = this.remoteService.creditCardInfo[i].typeid;
            this.remoteService.cardId = this.remoteService.creditCardInfo[i].id;
            this.remoteService.cardHolderName = this.remoteService.creditCardInfo[i].name;
            this.remoteService.cardNumber = this.remoteService.creditCardInfo[i].cardnumber;
            this.remoteService.cardExpiryDate = this.remoteService.creditCardInfo[i].expirydate;
            this.remoteService.cardCvvNumber = this.remoteService.creditCardInfo[i].cvv;
            this.remoteService.defaultCreditCard = true;

          }else{
            this.remoteService.selectedCreditCard = this.remoteService.creditCardInfo[i];
          }
          
          this.modelController.dismiss();
        }

      }
    }

  }
  payClick(){

  }
  
  editCreditCard(id){
    for (var i = 0; i < this.remoteService.creditCardInfo.length; i++) {
      if(this.remoteService.creditCardInfo[i].id === id){
        this.remoteService.cardType = this.remoteService.creditCardInfo[i].typeid;
        this.remoteService.cardId = this.remoteService.creditCardInfo[i].id;
        this.remoteService.cardHolderName = this.remoteService.creditCardInfo[i].name;
        this.remoteService.cardNumber = this.remoteService.creditCardInfo[i].cardnumber;
        this.remoteService.cardExpiryDate = this.remoteService.creditCardInfo[i].expirydate;
        this.remoteService.cardCvvNumber = this.remoteService.creditCardInfo[i].cvv;
      }
    }
    this.remoteService.creditCardEditPopup = true;
    this.modelController.create({component:CreateNewPaymentMethodPage}).then((modalElement)=>{
      modalElement.present();
    });
  }
  async confirmDeletePayCard(payCardId) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      //header: 'Alert',
      subHeader: 'Are you sure want to delete the card?',
      //message: 'This is an alert message.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          handler: () => {
            console.log('Confirm Delete');
          }
        }
      ]
    });

    await alert.present();
  }

  // markAsDefaultCard(selectedCardId){
  //   var selectedIndex = -1;
  //   for (var i = 0; i < this.remoteService.creditCardInfo.length; i++){
  //     this.remoteService.creditCardInfo[i]['isChecked'] = null;
  //     if (this.remoteService.creditCardInfo[i].id == selectedCardId){
  //       selectedIndex = i;
  //       this.selectedCreditId = selectedCardId;
  //       this.markAsDefault = true;
  //        $('#credit_card_'+ this.remoteService.creditCardInfo[i].id).addClass( 'active-box' );
  //       this.remoteService.creditCardInfo[selectedIndex].default = true;
  //     }
  //     else{
  //       $('#credit_card_'+ this.remoteService.creditCardInfo[i].id).removeClass( 'active-box' );
  //       this.remoteService.creditCardInfo[0].default = false;
  //     }

  //     if(selectedIndex !=-1){
  //       var temp = this.remoteService.creditCardInfo[0];
  //       this.remoteService.creditCardInfo[0] = this.remoteService.creditCardInfo[selectedIndex];
  //       this.remoteService.creditCardInfo[selectedIndex]= temp;
        
  //     }
  //   }
   
  // }

}
