import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

import { RemoteService } from '../../../providers/remote.service';

@Component({
  selector: 'app-address-fields-list',
  templateUrl: './address-fields-list.page.html',
  styleUrls: ['./address-fields-list.page.scss'],
})
export class AddressFieldsListPage implements OnInit {
  @ViewChild('searchList') searchList: any;
	addressFieldsList : any;
	renderAddressList : any;
	addressPath: any;
	checkedValue: any;

  constructor( private remoteService: RemoteService, public navParams: NavParams, public viewCtrl: ModalController) { 

    if (navParams.get('key')=='country') {
      this.renderAddressList = this.remoteService.country_list;
    }else if(navParams.get('key')=='state'){
      this.renderAddressList = [];
      for(var rsl in this.remoteService.state_list){
        this.renderAddressList.push(rsl)
      }
    }else if(navParams.get('key')=='city'){
      this.renderAddressList = this.remoteService.city_list;
    }
  	
  	this.addressPath = navParams.get('key');
    // if (navParams.get('key')=='state') {
    //   this.renderStatesList();
    // }

    // console.log('response')
  }

  ngOnInit() {
    // console.log('response1')
  }

  ngAfterViewInit(){
    // console.log('response2')
    // if (this.navParams.get('key')=='state') {
    //   setTimeout(() => {
    //     this.searchList.setFocus();
    //   }, 2000);
    // }else{
    //   setTimeout(() => {
    //     this.searchList.setFocus();
    //   }, 6000);
    // }
  }
  ngAfterViewChecked(){
    // console.log('response3')
    // if (this.navParams.get('key')=='state') {
      setTimeout(() => {
        this.searchList.setFocus();
      }, 2000);
    // }else{
    //   setTimeout(() => {
    //     this.searchList.setFocus();
    //   }, 6000);
    // }
  }

  
  renderStatesList(){
    this.renderAddressList = [];
    for(var npd in this.renderAddressList){
      this.renderAddressList.push(this.renderAddressList[npd])
    }
  }


  searchData(event){
  	const searchFields = event.target.value;
  	this.renderAddressList = this.filterFields(searchFields);
    //  || this.renderAddressList.length == 0
  	if (searchFields.length === 0) {
  		
      if (this.navParams.get('key')=='country') {
        this.renderAddressList = this.remoteService.country_list;
      }
      if (this.navParams.get('key')=='state') {
        this.renderAddressList = [];
        for(var rsl in this.remoteService.state_list){
          this.renderAddressList.push(rsl)
        }
        // this.renderStatesList();
      }
      if (this.navParams.get('key')=='city') {
        this.renderAddressList = this.remoteService.city_list;
      }
  	};
  }

  filterFields(value){
  	return this.renderAddressList.filter((item)=>{
  		return item.toLowerCase().indexOf(value.toLowerCase())>-1
  	})
  }


  async closeWindow(item){
  	// if (this.navParams.get('key')=='state') {
   //    item = item.split(' - ')[1];
   //  }
  	await this.viewCtrl.dismiss(item);
  }

}
