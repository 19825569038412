import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import {CreateNewAddressPage} from '../../modals/create-new-address/create-new-address.page';
import { RemoteService } from '../../../providers/remote.service';
import { AlertController } from '@ionic/angular';



@Component({
  selector: 'app-create-address-book',
  templateUrl: './create-address-book.page.html',
  styleUrls: ['./create-address-book.page.scss'],
})

// @Component({
//   selector: 'alert-example',
//   templateUrl: 'alert-example.html',
//   //styleUrls: ['./alert-example.css'],
// })
 

export class CreateAddressBookPage implements OnInit {
  checkedIdx = -1;
  checkedAddrIdx = -1;
  selectedShippingAddressId = "no_id";
  selectedBillingAddressId = "no_id";
  selectedDesignerAddressId = "no_id";
  selectedShippingdefaultAddresss =false;
  selectedBillingdefaultAddresss =false;
  selectedDesignerdefaultAddresss =false;
  markAsDefault = false;
  addressDeleted: boolean = false;
  addresses = [
    { type: 'OFFICE', default:'yes',   LastName: 'James', FirstName:'Smith', Address1:'606-3727 Ullamcorper. Street', Address2:'', City:'Roseville', State:'NH', Country:'USA', Zip:'11523', Phone:'(786) 713-8616' ,addressId:'3', addressTypeId:'1'  },
    { type: 'HOME',  default:'no', LastName: 'Maria', FirstName:'Rodriguez', Address1:'P.O. Box 887 2508 Dolor. Av.', Address2:'', City:'Muskegon', State:'KY', Country:'USA', Zip:'12482', Phone:'(314) 244-6306'   }
    //,{ type: 'HOME',   LastName: 'Maria', FirstName:'Rodriguez', Address1:'P.O. Box 887 2508 Dolor. Av.', Address2:'', City:'Muskegon', State:'KY', Country:'USA', Zip:'12482', Phone:'(314) 244-6306'   }
  ];
  

  constructor(private modelController:ModalController,private activatedRoute: ActivatedRoute,private remoteService: RemoteService, private alertController:AlertController) { }

  ngOnInit() {
    this.remoteService.getAddressDetails('book').then((res: any)=>{
      // setTimeout(()=>{
        if(this.remoteService.alladdress.length>0){
          for(var adl in this.remoteService.alladdress){
            this.remoteService.alladdress[adl]['isCheckedDeliver'] = null;
            if (this.remoteService.shippingAddressPopup) {
              if (this.remoteService.alladdress[adl].isDefaultForShipping == '1' || this.remoteService.alladdress[adl].isDefaultForShipping == 1) { 
                this.remoteService.alladdress[adl]['isCheckedDeliver'] = true;
                this.deliverToAddress(this.remoteService.alladdress[adl],adl,'');
              }
            }
            if (this.remoteService.designerAddressPopup) {
              if (this.remoteService.alladdress[adl].isDefaultForDesign == '1' || this.remoteService.alladdress[adl].isDefaultForDesign == 1) { 
                this.remoteService.alladdress[adl]['isCheckedDeliver'] = true;
                this.deliverToAddress(this.remoteService.alladdress[adl],adl,'')
              }
            }
            if (this.remoteService.billingAddressPopup) {
              if (this.remoteService.alladdress[adl].isDefaultForBilling == '1' || this.remoteService.alladdress[adl].isDefaultForBilling == 1) { 
                this.remoteService.alladdress[adl]['isCheckedDeliver'] = true;
                this.deliverToAddress(this.remoteService.alladdress[adl],adl,'')
              }
            }
          }
        }
      // },500)
    });
  }
  async confirmDeleteAddress(addressData,addressTypeId) {
    this.remoteService.address_type_id =  addressTypeId;
    // if(addressTypeId == '3'){
    //   if (this.remoteService.selectedShippingAddress.default == addressData['default']) {
    //     this.remoteService.selectedShippingAddress.addressId = addressData['addressId']
    //   }
    // }
    // if (addressTypeId == '2'){
    //   if (this.remoteService.selectedBillingAddress.default == addressData['default']) {
    //     this.remoteService.selectedBillingAddress.addressId = addressData['addressId']
    //   }
    // }
    // if (addressTypeId == '1'){
    //   // if (this.selectedDesignerAddress.address1 = ['address1'] && this.selectedDesignerAddress.country = ['country'] && this.selectedDesignerAddress.state = ['state'] && this.selectedDesignerAddress. = ['']) {}
    //   if (this.remoteService.selectedDesignerAddress.default == addressData['default']) {
    //     this.remoteService.selectedDesignerAddress.addressId = addressData['addressId']
    //   }
    // }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      //header: 'Alert',
      subHeader: 'Are you sure want to delete the card?',
      //message: 'This is an alert message.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          cssClass: 'danger delete-address',
          handler: () => {
            console.log('Confirm Delete');
            this.deleteSelctedAddress(addressData['addressId']);
          }
        }
      ]
    });

    await alert.present();
  }
  
  checkboxClick(adrsId){
    if (this.remoteService.shippingAddressPopup){
      if(this.checkedIdx == -1){
        this.selectedShippingAddressId = adrsId;
       // $( '#ac_'+adrsId ).addClass( 'active-box' ); 
      }else{
        this.selectedShippingAddressId = 'no_id';
        //$( '#ac_'+adrsId ).removeClass( 'active-box' ); 
      }
    }
    if (this.remoteService.billingAddressPopup){
      if(this.checkedIdx == -1){
        this.selectedBillingAddressId = adrsId;
        //$( '#ac_'+adrsId ).addClass( 'active-box' ); 
      }else{
        this.selectedBillingAddressId = 'no_id';
        //$( '#ac_'+adrsId ).removeClass( 'active-box' );
      }
    }
    if (this.remoteService.designerAddressPopup){
      if(this.checkedIdx == -1){
        this.selectedDesignerAddressId = adrsId;
        //$( '#ac_'+adrsId ).addClass( 'active-box' ); 
      }else{
        this.selectedDesignerAddressId = 'no_id';
        //$( '#ac_'+adrsId ).removeClass( 'active-box' );
      }
    }
  }

  addNewAddress(){
    this.modelController.create({component:CreateNewAddressPage,componentProps: {},backdropDismiss:false}).then((modalElement)=>{
      modalElement.present();
    })
    this.modelController.dismiss();
  }
  deleteSelctedAddress(id){
    this.remoteService.deleteAddress(id).then((res: any)=>{
      if(res['status: '] == 'success'){
        for (var i = 0; i < this.remoteService.alladdress.length; i++){
          if(this.remoteService.alladdress[i].addressId === id){
            this.addressDeleted = true;
            this.remoteService.alladdress.splice(i,1);
            if (this.remoteService.alladdress.length == 0) {
              if(this.remoteService.address_type_id == '3' ){
                this.remoteService.selectedShippingAddress = {};
              }
              if(this.remoteService.address_type_id == '2'){
                this.remoteService.selectedBillingAddress = {};
              }
              if(this.remoteService.address_type_id == '1'){
                this.remoteService.selectedDesignerAddress = {};
              }
            }
            break;
          }
        }
        if (this.remoteService.selectedShippingAddress && id == this.remoteService.selectedShippingAddress.addressId){
          this.remoteService.selectedShippingAddress = {};
          this.selectedShippingAddressId = 'no_id';
        }
        if (this.remoteService.selectedBillingAddress && id == this.remoteService.selectedBillingAddress.addressId){
          this.remoteService.selectedBillingAddress = {};
          this.selectedBillingAddressId = 'no_id';
        }
        if (this.remoteService.selectedDesignerAddress && id == this.remoteService.selectedDesignerAddress.addressId){
          this.remoteService.selectedDesignerAddress = {};
          this.selectedDesignerAddressId = 'no_id';
        }
      }
    });
    // this.modelController.dismiss();
  }

  
  selectAddress(){
    if (this.remoteService.shippingAddressPopup){
      if (this.selectedShippingAddressId == 'no_id'){
        alert('Please select a shipping address');
      }else{
        console.log(this.remoteService.alladdress);
        var defaultCheck = false;
        for (var i = 0; i < this.remoteService.alladdress.length; i++){
          if(this.remoteService.alladdress[i].isCheckedDeliver !=null){
            defaultCheck = true;
          }
          if (this.remoteService.alladdress[i].addressId == this.selectedShippingAddressId){
            //console.log(this.remoteService.alladdress[i])
            // if(this.markAsDefault){
              this.remoteService.address_id = this.remoteService.alladdress[i].addressId ;
              // this.remoteService.address_type_id = this.remoteService.alladdress[i].addressTypeId ;
              this.remoteService.address_type_id = [] ;
              // this.remoteService.address_type_id[0] = '3' ;
              this.remoteService.address_type_id.push('3');
              this.remoteService.alladdress[i].isDefaultForBilling == "1"? this.remoteService.address_type_id.push('2'):'0';
              this.remoteService.alladdress[i].isDefaultForDesign == "1"? this.remoteService.address_type_id.push('1'):'0';
              this.remoteService.contact_first_name = this.remoteService.alladdress[i].firstName ;
              this.remoteService.contact_last_name = this.remoteService.alladdress[i].lastName ;
              this.remoteService.contact_email = this.remoteService.alladdress[i].email ;
              this.remoteService.contact_phone = this.remoteService.alladdress[i].phoneNumber ;
              this.remoteService.contact_company = this.remoteService.alladdress[i].companyName ;
              this.remoteService.contact_address1 = this.remoteService.alladdress[i].addressLine1 ;
              this.remoteService.contact_address2 = this.remoteService.alladdress[i].addressLine2 ;
              this.remoteService.contact_city = this.remoteService.alladdress[i].city ;
              this.remoteService.contact_country = this.remoteService.alladdress[i].country ;
              this.remoteService.contact_state = this.remoteService.alladdress[i].state ;
              this.remoteService.contact_zipcode = this.remoteService.alladdress[i].zip ;
              // this.remoteService.default_add_address = true ;

              //To update
            //   this.remoteService.updateAddressDetails(this.remoteService.alladdress[i].addressId );
            // }else{

              this.makingDefaultAddress(this.remoteService.address_id);
              // this.remoteService.selectedShippingAddress = this.remoteService.alladdress[i];
            // }
            
            //this.remoteService.getShippingOptions();
            // this.modelController.dismiss(defaultCheck,'save');
          }

        }
        if(!defaultCheck && this.remoteService.alladdress.length == 0){
          this.remoteService.selectedShippingAddress = {};
          this.modelController.dismiss(this.addressDeleted,'save');
        }
      }
    }
    if (this.remoteService.billingAddressPopup){
      if (this.selectedBillingAddressId == 'no_id'){
        alert('Please select a billing address');
      }else{
        var defaultCheck = false;
        for (var i = 0; i < this.remoteService.alladdress.length; i++){
          if(this.remoteService.alladdress[i].isCheckedDeliver !=null){
            defaultCheck = true;
          }
          if (this.remoteService.alladdress[i].addressId == this.selectedBillingAddressId){
            //console.log(this.remoteService.alladdress[i])
            // if(this.markAsDefault){
              this.remoteService.address_id = this.remoteService.alladdress[i].addressId ;
              // this.remoteService.address_type_id = this.remoteService.alladdress[i].addressTypeId ;
              this.remoteService.address_type_id = [] ;
              // this.remoteService.address_type_id[0] = '2' ;
              this.remoteService.alladdress[i].isDefaultForShipping == "1"? this.remoteService.address_type_id.push('3'):'0';
              this.remoteService.address_type_id.push('2');
              this.remoteService.alladdress[i].isDefaultForDesign == "1"? this.remoteService.address_type_id.push('1'):'0';
              this.remoteService.contact_first_name = this.remoteService.alladdress[i].firstName ;
              this.remoteService.contact_last_name = this.remoteService.alladdress[i].lastName ;
              this.remoteService.contact_email = this.remoteService.alladdress[i].email ;
              this.remoteService.contact_phone = this.remoteService.alladdress[i].phoneNumber ;
              this.remoteService.contact_company = this.remoteService.alladdress[i].companyName ;
              this.remoteService.contact_address1 = this.remoteService.alladdress[i].addressLine1 ;
              this.remoteService.contact_address2 = this.remoteService.alladdress[i].addressLine2 ;
              this.remoteService.contact_city = this.remoteService.alladdress[i].city ;
              this.remoteService.contact_country = this.remoteService.alladdress[i].country ;
              this.remoteService.contact_state = this.remoteService.alladdress[i].state ;
              this.remoteService.contact_zipcode = this.remoteService.alladdress[i].zip ;
              this.remoteService.default_add_address = true ;

              //To update
            //   this.remoteService.updateAddressDetails(this.remoteService.alladdress[i].addressId );
            // }else{
              this.makingDefaultAddress(this.remoteService.address_id);
              // this.remoteService.selectedBillingAddress = this.remoteService.alladdress[i];
            // }
            
            // this.modelController.dismiss();
          }

        }
        if(!defaultCheck && this.remoteService.alladdress.length == 0){
          this.remoteService.selectedBillingAddress = {};
          this.modelController.dismiss();
        }
      }
    }
    if (this.remoteService.designerAddressPopup){
      if (this.selectedDesignerAddressId == 'no_id'){
        alert('Please select a designer address');
      }else{
        var defaultCheck = false;
        for (var i = 0; i < this.remoteService.alladdress.length; i++){
          if(this.remoteService.alladdress[i].isCheckedDeliver !=null){
            defaultCheck = true;
          }
          if (this.remoteService.alladdress[i].addressId == this.selectedDesignerAddressId){
            //console.log(this.remoteService.alladdress[i])
            // if(this.markAsDefault){
              this.remoteService.address_id = this.remoteService.alladdress[i].addressId ;
              // this.remoteService.address_type_id = this.remoteService.alladdress[i].addressTypeId ;
              this.remoteService.address_type_id = [] ;
              // this.remoteService.address_type_id[0] = '1' ;
              this.remoteService.alladdress[i].isDefaultForShipping == "1"? this.remoteService.address_type_id.push('3'):'0';
              this.remoteService.alladdress[i].isDefaultForBilling == "1"? this.remoteService.address_type_id.push('2'):'0';
              this.remoteService.address_type_id.push('1');
              this.remoteService.contact_first_name = this.remoteService.alladdress[i].firstName ;
              this.remoteService.contact_last_name = this.remoteService.alladdress[i].lastName ;
              this.remoteService.contact_email = this.remoteService.alladdress[i].email ;
              this.remoteService.contact_phone = this.remoteService.alladdress[i].phoneNumber ;
              this.remoteService.contact_company = this.remoteService.alladdress[i].companyName ;
              this.remoteService.contact_address1 = this.remoteService.alladdress[i].addressLine1 ;
              this.remoteService.contact_address2 = this.remoteService.alladdress[i].addressLine2 ;
              this.remoteService.contact_city = this.remoteService.alladdress[i].city ;
              this.remoteService.contact_country = this.remoteService.alladdress[i].country ;
              this.remoteService.contact_state = this.remoteService.alladdress[i].state ;
              this.remoteService.contact_zipcode = this.remoteService.alladdress[i].zip ;
              this.remoteService.default_add_address = true ;

              //To update
            //   this.remoteService.updateAddressDetails(this.remoteService.alladdress[i].addressId );
            // }else{
              this.makingDefaultAddress(this.remoteService.address_id);
              // this.remoteService.selectedDesignerAddress = this.remoteService.alladdress[i];
            // }
            
            // this.modelController.dismiss();
          }

        }
        if(!defaultCheck && this.remoteService.alladdress.length == 0){
          this.remoteService.selectedDesignerAddress = {};
          this.modelController.dismiss();
        }
      }
    }
  }
  closeModal(param){
    this.modelController.dismiss(this.addressDeleted,param);
  }

  editAddress(addressId,addressTypeId){
    for(var i=0; i<this.remoteService.alladdress.length;i++){
      if(this.remoteService.alladdress[i].addressId == addressId){
        //console.log("default",this.remoteService.alladdress[i].default);
        
        this.remoteService.address_id = this.remoteService.alladdress[i].addressId;
        // this.remoteService.address_type_id = this.remoteService.alladdress[i].addressTypeId.toString();
        this.remoteService.contact_first_name = this.remoteService.alladdress[i].firstName;
        this.remoteService.contact_last_name = this.remoteService.alladdress[i].lastName;
        this.remoteService.contact_address1 = this.remoteService.alladdress[i].addressLine1;
        this.remoteService.contact_address2 = this.remoteService.alladdress[i].addressLine2;
        this.remoteService.contact_state = this.remoteService.alladdress[i].state;
        this.remoteService.contact_city = this.remoteService.alladdress[i].city;
        this.remoteService.contact_country = this.remoteService.alladdress[i].country;
        this.remoteService.contact_zipcode = this.remoteService.alladdress[i].zip;
        this.remoteService.contact_phone = this.remoteService.alladdress[i].phoneNumber;
        this.remoteService.default_add_address = this.remoteService.alladdress[i].default == "1" ? true:false;
        this.remoteService.contact_user_id = this.remoteService.alladdress[i].userId;
        this.remoteService.contact_email = this.remoteService.alladdress[i].email;
        this.remoteService.contact_company = this.remoteService.alladdress[i].companyName;
        this.remoteService.address_type_id = [];
        (this.remoteService.alladdress[i]['isDefaultForShipping'] == '1'?this.remoteService.address_type_id.push('3'):'');
        (this.remoteService.alladdress[i]['isDefaultForBilling'] == '1'?this.remoteService.address_type_id.push('2'):'');
        (this.remoteService.alladdress[i]['isDefaultForDesign'] == '1'?this.remoteService.address_type_id.push('1'):'');
      }
    }
    this.remoteService.addressEditPopup = true;
    this.remoteService.addressAction = "Edit address"
    this.remoteService.addressBtnText = "Save"
  //   this.modelController.create({component:CreateNewAddressPage,componentProps: {},backdropDismiss:false}).then((modalElement)=>{
  //     modalElement.present();
  //  })
    this.modelController.dismiss('','edit');
  }

  addNewAddressToBook(){
    this.modelController.create({component:CreateNewAddressPage,componentProps: {},backdropDismiss:false}).then((modalElement)=>{
      modalElement.present();
    })
    this.modelController.dismiss();
  }

  // markAsDefaultAddress(selectedAddressId) {
  //   var selectedIndex = -1;
  //   if (this.remoteService.shippingAddressPopup){
  //     for (let index = 0; index < this.remoteService.alladdress.length; index++) {

  //       this.remoteService.alladdress[index]['isChecked'] = null;
  //       if (      this.remoteService.alladdress[index].addressId == selectedAddressId){
  //         selectedIndex = index;
  //         this.selectedShippingAddressId = selectedAddressId;
  //         this.markAsDefault = true;
  //         $('#ac_'+ this.remoteService.alladdress[index].addressId).addClass( 'active-box' ); 
  //         this.remoteService.alladdress[selectedIndex].default = true;
  //       }
  //       else{
  //         this.remoteService.alladdress[0].default = false;
  //          $('#ac_'+ this.remoteService.alladdress[index].addressId).removeClass( 'active-box' );
  //       }      
  //     }
  
  //     if(selectedIndex !=-1){
  //       var temp = this.remoteService.alladdress[0];
  //       this.remoteService.alladdress[0] = this.remoteService.alladdress[selectedIndex];
  //       this.remoteService.alladdress[selectedIndex]= temp; 
  //     }
  //   }
    
  //   if (this.remoteService.billingAddressPopup){
  //     for (let index = 0; index < this.remoteService.alladdress.length; index++) {

  //       this.remoteService.alladdress[index]['isChecked'] = null;
  //       if (      this.remoteService.alladdress[index].addressId == selectedAddressId){
  //         selectedIndex = index;
  //         this.selectedBillingAddressId = selectedAddressId;
  //         this.markAsDefault = true;
  //         $('#ac_'+ this.remoteService.alladdress[index].addressId).addClass( 'active-box' ); 
  //         this.remoteService.alladdress[selectedIndex].default = true;
  //       }
  //       else{
  //         $('#ac_'+ this.remoteService.alladdress[index].addressId).removeClass( 'active-box' );
  //         this.remoteService.alladdress[0].default = false;
  //       }      
  //     }
  
  //     if(selectedIndex !=-1){
  //       var temp = this.remoteService.alladdress[0];
  //       this.remoteService.alladdress[0] = this.remoteService.alladdress[selectedIndex];
  //       this.remoteService.alladdress[selectedIndex]= temp;
  //     }
  //   }

  //   if (this.remoteService.designerAddressPopup){
  //     for (let index = 0; index < this.remoteService.alladdress.length; index++) {

  //       this.remoteService.alladdress[index]['isChecked'] = null;
  //       if (      this.remoteService.alladdress[index].addressId == selectedAddressId){
  //         selectedIndex = index;
  //         this.selectedDesignerAddressId = selectedAddressId;
  //         this.markAsDefault = true;
  //         $('#ac_'+ this.remoteService.alladdress[index].addressId).addClass( 'active-box' ); 
  //         this.remoteService.alladdress[selectedIndex].default = true;
  //       }
  //       else{
  //         $('#ac_'+ this.remoteService.alladdress[index].addressId).removeClass( 'active-box' );
  //         this.remoteService.alladdress[0].default = false;
  //       }      
  //     }
  
  //     if(selectedIndex !=-1){
  //       var temp = this.remoteService.alladdress[0];
  //       this.remoteService.alladdress[0] = this.remoteService.alladdress[selectedIndex];
  //       this.remoteService.alladdress[selectedIndex]= temp;
  //     }
  //   }
    
  // }

  deliverToAddress(selectedAddressData,adl,route) {
    //alert("selectedAddressId = "+ selectedAddressId)
    var selectedIndex = -1;
    // if (this.remoteService.shippingAddressPopup){
      // for (let index = 0; index < this.remoteService.alladdress.length; index++) {
        if (this.remoteService.alladdress[adl].addressId == selectedAddressData.addressId){
          selectedIndex = adl;
          // this.selectedShippingAddressId = selectedAddressData.addressId;
          // 431 and 432 and 434 lines code are making hide as per 11th Jan release
          $('#selectAdd_'+selectedAddressData.addressId).hide();
          $('#selcted_lbl_'+selectedAddressData.addressId).addClass('selectedCard');
          $('#ac_'+ this.remoteService.alladdress[adl].addressId).addClass( 'active-box' );
          //this.remoteService.alladdress[selectedIndex].default = true;
        }
        else{
          // 439 and 440 lines code are making hide as per 11th Jan release
          $('#selectAdd_'+ this.remoteService.alladdress[adl].addressId).show();
          $('#selcted_lbl_'+ this.remoteService.alladdress[adl].addressId).removeClass('selectedCard');
          $('#ac_'+ this.remoteService.alladdress[adl].addressId).removeClass( 'active-box' );
          //this.remoteService.alladdress[0].default = false;
        }      

        if (this.remoteService.shippingAddressPopup){
          this.selectedShippingAddressId = selectedAddressData.addressId;
        }
        if (this.remoteService.billingAddressPopup){
          this.selectedBillingAddressId = selectedAddressData.addressId;
        }
        if (this.remoteService.designerAddressPopup){
          this.selectedDesignerAddressId = selectedAddressData.addressId;
        }
      // }
      // if(selectedIndex !=-1){
      //   var temp = this.remoteService.alladdress[0];
      //   this.remoteService.alladdress[0] = this.remoteService.alladdress[selectedIndex];
      //   this.remoteService.alladdress[selectedIndex]= temp; 
      // }
    // }

    // if (this.remoteService.billingAddressPopup){
    //   // for (let index = 0; index < this.remoteService.alladdress.length; index++) {
    //     // this.remoteService.alladdress[index]['isCheckedDeliver'] = null;
    //     if (this.remoteService.alladdress[adl].addressId == selectedAddressId.addressId){
    //       selectedIndex = adl;
    //       this.selectedBillingAddressId = selectedAddressId.addressId;
    //       // 459 and 462 and 460 lines code are making hide as per 11th Jan release
    //       $('#selectAdd_'+selectedAddressId).hide();
    //       $('#selcted_lbl_'+selectedAddressId).addClass('selectedCard');
    //       $('#ac_'+ this.remoteService.alladdress[adl].addressId).addClass( 'active-box' ); 
    //       // this.remoteService.alladdress[adl]['isCheckedDeliver'] = true;
    //       //this.remoteService.alladdress[selectedIndex].default = true;
    //     }
    //     else{
    //       // 467 and 468 lines code are making hide as per 11th Jan release
    //       $('#selectAdd_'+ this.remoteService.alladdress[adl].addressId).show();
    //       $('#selcted_lbl_'+ this.remoteService.alladdress[adl].addressId).removeClass('selectedCard');
    //       $('#ac_'+ this.remoteService.alladdress[adl].addressId).removeClass( 'active-box' );
    //       //this.remoteService.alladdress[0].default = false;
    //     }      
    //   // }
    //   // if(selectedIndex !=-1){
    //   //   var temp = this.remoteService.alladdress[0];
    //   //   this.remoteService.alladdress[0] = this.remoteService.alladdress[selectedIndex];
    //   //   this.remoteService.alladdress[selectedIndex]= temp; 
    //   // }
    // }

    // if (this.remoteService.designerAddressPopup){
    //   // for (let index = 0; index < this.remoteService.alladdress.length; index++) {
    //     // this.remoteService.alladdress[index]['isCheckedDeliver'] = null;
    //     if (this.remoteService.alladdress[index].addressId == selectedAddressId.addressId){
    //       selectedIndex = adl;
    //       this.selectedDesignerAddressId = selectedAddressId.addressId;
    //       // 487 and 488 and 490 lines code are making hide as per 11th Jan release
    //       $('#selectAdd_'+selectedAddressId).hide();
    //       $('#selcted_lbl_'+selectedAddressId).addClass('selectedCard');
    //       $('#ac_'+this.remoteService.alladdress[adl].addressId).addClass( 'active-box' );  
    //       // this.remoteService.alladdress[adl]['isCheckedDeliver'] = true;
    //       //this.remoteService.alladdress[selectedIndex].default = true;
    //     }
    //     else{
    //       // 494 and 495 lines code are making hide as per 11th Jan release
    //       $('#selectAdd_'+ this.remoteService.alladdress[adl].addressId).show();
    //       $('#selcted_lbl_'+ this.remoteService.alladdress[adl].addressId).removeClass('selectedCard');
    //       $('#ac_'+this.remoteService.alladdress[adl].addressId).removeClass( 'active-box' );
    //       //this.remoteService.alladdress[0].default = false;
    //     }      
    //   // }
    //   // if(selectedIndex !=-1){
    //   //   var temp = this.remoteService.alladdress[0];
    //   //   this.remoteService.alladdress[0] = this.remoteService.alladdress[selectedIndex];
    //   //   this.remoteService.alladdress[selectedIndex]= temp; 
    //   // }
    // }
    if (route != 'click') {
      this.remoteService.alladdress.splice(0,0,this.remoteService.alladdress[adl]);
      this.remoteService.alladdress.splice(Number(adl)+1,1);
    }
  }

  viewDeliverToAddress(selectedAddressData,adl){
    for (let index = 0; index < this.remoteService.alladdress.length; index++) {
      this.remoteService.alladdress[index]['isCheckedDeliver'] = null;
      $('#selectAdd_'+ this.remoteService.alladdress[index].addressId).show();
      $('#selcted_lbl_'+ this.remoteService.alladdress[index].addressId).removeClass('selectedCard');
      $('#ac_'+ this.remoteService.alladdress[index].addressId).removeClass( 'active-box' );
      if (this.remoteService.alladdress[index].addressId == selectedAddressData.addressId){
        this.deliverToAddress(this.remoteService.alladdress[index],index,'click')
      }
    }
  }

  makingDefaultAddress(addressId){
    this.remoteService.updateNewAddressDetails(addressId).subscribe((data)=>{
      // this.newResponseAddress(data,'update');
      if (data) {
        let newAddressObj = {
          firstName        :data.firstname,
          lastName         :data.lastname,
          addressLine1     :data.address1,
          addressLine2     :data.address2,
          city             :data.city,
          state            :data.state,
          country          :data.country,
          zip              :data.zip,
          phoneNumber      :data.phone,
          fax              :data.fax, 
          // default          :data.defaultForType,
          addressId        :data.addressid,
          // addressTypeId    :data.addressType,
          email            :data.email,
          companyName      :data.company,
          isDefaultForBilling :data.isDefaultForBilling,
          isDefaultForDesign  :data.isDefaultForDesign,
          isDefaultForShipping:data.isDefaultForShipping
        }
        if (this.remoteService.shippingAddressPopup){
          this.remoteService.selectedShippingAddress = newAddressObj;
          this.modelController.dismiss(true,'save');
        }
        if (this.remoteService.billingAddressPopup){
          this.remoteService.selectedBillingAddress = newAddressObj;
          this.modelController.dismiss();
        }
        if (this.remoteService.designerAddressPopup){
          this.remoteService.selectedDesignerAddress = newAddressObj;
          this.modelController.dismiss();
        }

        this.remoteService.getAddressDetails('list').then(()=>{});
      }
    });
  }
}
