import { Component,HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

import * as XLSX from 'xlsx';
const { read, write, utils } = XLSX;

import { RemoteService } from '../app/providers/remote.service';
@HostListener('touchstart')
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  navigate : any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public remoteService: RemoteService,
    private router: Router,public idle: Idle,private keepalive: Keepalive
  ) {
    this.sideMenu();
    this.initializeApp();
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(1800);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1800);
    //idle.setTimeout(3595);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.router.navigateByUrl('/session-timeout-model');
    });
    // idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    // idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    // keepalive.interval(15);

    // keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

   
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  sideMenu()
  {
    this.navigate =
    [
      {
        title : "Home",
        url   : "/home",
        icon  : "home"
      },
      {
        title : "Chat",
        url   : "/chat",
        icon  : "chatboxes"
      },
      {
        title : "Contacts",
        url   : "/contacts",
        icon  : "contacts"
      },
    ]
  }

  onTouchStart() {
  //this.restartIdleLogoutTimer();
  }

  idleLogoutTimer:any;

  ngOnInit()  {
    //this.restartIdleLogoutTimer();
    // this.idle.setIdle(5);
    // this.idle.setTimeout(5);
    // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    // this.idle.onTimeout.subscribe(() => {
    //   this.idleState = 'Timed out!';
    //   this.timedOut = true;
    //   // setTimeout(()=>{
    //   //   this.router.navigateByUrl('/session-timeout-model');
    //   // },120000);
    // });
    // console.log("this.timedOut",this.timedOut);
    // console.log("this.idleState",this.idleState);
    
  }

  restartIdleLogoutTimer() {
    clearTimeout(this.idleLogoutTimer);
    console.log("this.idleLogoutTimer ",this.idleLogoutTimer );
    this.idleLogoutTimer = setTimeout(()=>{
      this.router.navigateByUrl('/session-timeout-model');
    },120000); //60min 3600000
  }


}
