import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { RemoteService } from './providers/remote.service';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {NgIdleModule} from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import {CreateNewPaymentMethodPageModule} from './pages/modals/create-new-payment-method/create-new-payment-method.module';
import {CreateNewAddressPageRoutingModule} from './pages/modals/create-new-address/create-new-address-routing.module';
import {CreateAddressBookPageRoutingModule} from './pages/modals/create-address-book/create-address-book-routing.module';
import { PaymentBookPageRoutingModule} from './pages/modals/payment-book/payment-book-routing.module';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule 
              , HttpClientModule 
              , CreateNewPaymentMethodPageModule, CreateNewAddressPageRoutingModule
              ,CreateAddressBookPageRoutingModule, PaymentBookPageRoutingModule, NgIdleKeepaliveModule.forRoot()],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    RemoteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
