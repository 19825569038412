import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreateNewPaymentMethodPageRoutingModule } from './create-new-payment-method-routing.module';

import { CreateNewPaymentMethodPage } from './create-new-payment-method.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreateNewPaymentMethodPageRoutingModule
  ],
  declarations: [CreateNewPaymentMethodPage]
})
export class CreateNewPaymentMethodPageModule {}
