import { splitAtColon } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import * as $ from 'jquery';

import { RemoteService } from '../../../providers/remote.service';

@Component({
  selector: 'app-create-new-payment-method',
  templateUrl: './create-new-payment-method.page.html',
  styleUrls: ['./create-new-payment-method.page.scss'],
})
export class CreateNewPaymentMethodPage implements OnInit {

  myresult=[
    {
      cardType:"",
      cardHolderName:"",
      cardNumber:"",
      cardExpiryDate:"",
      cardCvvNumber:"",
      defaultCreditCard:"",
      typeid:"",
      id:""

    }
  ];

  customPopoverOptions: any = {  
       
  };
  
  constructor(private modelController:ModalController,
              private remoteService: RemoteService,
              public alertController: AlertController) { }

  ngOnInit() {

  }
  async addorEditCreditcard(){
    var message = "";
    var dateRegEx1 = new RegExp(/^(0?[1-9]|1[012])[\/\-]\d{4}$/);
    if ((!message) && (this.remoteService.cardHolderName == null
      || this.remoteService.cardHolderName==undefined
      || this.remoteService.cardHolderName==""
      || this.remoteService.cardHolderName=="")){
        message = "Card holder name is required";
        $("#name_on_card").focus();
    }
    if ((!message) && (this.remoteService.cardNumber == null
      || this.remoteService.cardNumber==undefined
      || this.remoteService.cardNumber=="")){
        message = "Card number is required";

    }
    if ((!message) && (this.remoteService.cardExpiryDate == null
      || this.remoteService.cardExpiryDate==undefined
      || this.remoteService.cardExpiryDate=="")){
        message = "Card expiry date is required";

    }
    if ((!message) && (this.remoteService.cardCvvNumber == null
        || this.remoteService.cardCvvNumber==undefined
        || this.remoteService.cardCvvNumber=="")){
          message = "Card cvv number is required";

    }
    if ((!message) && (this.remoteService.cardType == null
      || this.remoteService.cardType==undefined
      || this.remoteService.cardType=="")){
        message = "Please select a card type";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="1") &&
      (this.remoteService.cardNumber.toString()[0] != "6")
      ){
        message = "All Discover Credit cards must start with digit 6";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="1") &&
      (this.remoteService.cardNumber.toString().length != 16)
      ){
        message = "Please enter a valid Credit Card No. Note that AMEX card needs 15 digits and VISA/ MASTERCARD/ Discover Cards needs 16 digits";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="2") &&
      (this.remoteService.cardNumber.toString()[0] != "3" )
      ){
        message = "All American Express Credit cards must start with digit 3";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="2") &&
      (this.remoteService.cardNumber.toString().length != 15)
      ){
        message = "Please enter a valid Credit Card No. Note that AMEX card needs 15 digits and VISA/ MASTERCARD/ Discover Cards needs 16 digits";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="3") &&
      (this.remoteService.cardNumber.toString()[0] != "4" )
      ){
        message = "All Visa Credit cards must start with digit 4";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="3") &&
      (this.remoteService.cardNumber.toString().length != 16)
      ){
        message = "Please enter a valid Credit Card No. Note that AMEX card needs 15 digits and VISA/ MASTERCARD/ Discover Cards needs 16 digits";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="4") &&
      (this.remoteService.cardNumber.toString()[0] != "5" )
      ){
        message = "All Master Card Credit cards must start with digit 5";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardNumber)
      && (this.remoteService.cardType=="4") &&
      (this.remoteService.cardNumber.toString().length != 16)
      ){
        message = "Please enter a valid Credit Card No. Note that AMEX card needs 15 digits and VISA/ MASTERCARD/ Discover Cards needs 16 digits";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardCvvNumber)
      && (this.remoteService.cardType=="2") &&
      (this.remoteService.cardCvvNumber.toString().length != 4)
      ){
        message = "American Express card should have 4 digit CVV number";

    }
    if ((!message) && (this.remoteService.cardType)
      && (this.remoteService.cardCvvNumber)
      && (this.remoteService.cardType=="1" || this.remoteService.cardType=="3" || this.remoteService.cardType=="4") &&
      (this.remoteService.cardCvvNumber.toString().length != 3)
      ){
        message = "VISA/MASTERCARD/DISCOVER card should have 3 digit CVV number";

    }
    if ((!message) && (!dateRegEx1.test(this.remoteService.cardExpiryDate))){
        message = "Expiry Date format is not correct";

    }else if(dateRegEx1.test(this.remoteService.cardExpiryDate)){
      if(this.remoteService.cardExpiryDate.split('/')[0].length == 1){
        this.remoteService.cardExpiryDate = "0" + this.remoteService.cardExpiryDate
      }
      if(new Date().getFullYear() > parseInt(this.remoteService.cardExpiryDate.split('/')[1])){
        message = "Please provide a valid expiry date";
      }else if (new Date().getFullYear() == parseInt(this.remoteService.cardExpiryDate.split('/')[1])){
        if(new Date().getMonth() + 1 > parseInt(this.remoteService.cardExpiryDate.split('/')[0])){
          message = "Please provide a valid expiry date";
        }
      }
    }
    if (message){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      subHeader: 'Validation error',
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }else{
      var cardtype = this.remoteService.cardType;
      var name     = this.remoteService.cardHolderName;
      var cardnumber = this.remoteService.cardNumber;
      var expirydate  = this.remoteService.cardExpiryDate;
      var cvv      = this.remoteService.cardCvvNumber;
      var defaultcard  = this.remoteService.defaultCreditCard;
      var card_details = {
        name       : name,
        cardtype   : cardtype,
        cardnumber : cardnumber,
        expirydate : expirydate,
        cvv        : cvv,
        id         : "1",
        typeid     : "1",
        defaultcard: defaultcard
      };
      this.remoteService.selectedCreditCard = card_details
      this.modelController.dismiss();
      // console.log("result", MYRESULTS);
    // }
  }
  }
  closeModal(){
    this.modelController.dismiss();
  }
}
