import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
//import {FormsModule, ReactiveFormsModule,FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as $ from 'jquery';
import { FormGroup, FormControl, Validators} from '@angular/forms';
//import { ActivatedRoute } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { RemoteService } from '../../../providers/remote.service';
import { AddressFieldsListPage } from '../../modals/address-fields-list/address-fields-list.page';
const resolvedPromise = Promise.resolve(null);
@Component({
  selector: 'app-create-new-address',
  templateUrl: './create-new-address.page.html',
  styleUrls: ['./create-new-address.page.scss'],
})
export class CreateNewAddressPage implements OnInit {
  checkMe = "0";
  //public value = this.navParams.get('value');
  editAddress;
  showCityInputText = true;
  defaultAddressCheck: any;
  saveAddressCount: any = [];
   //addressDetailsForm:FormGroup;
//    addressDetailsForm = this.FormBuilder.group({
//     addressType: [''],
//     firstName: [''],
//     lastName: [''],
//     address1: [''],
//     country: [''],
//     state: [''],
//     city: [''],
//     zipcode: [''],
//     phoneNumber: [''],
// });
  customPopoverOptions: any = {  
       cssClass: 'button-css btn-interchange'
       
  }; 
  city_list=['Sunnyvale','Acampo', 'Acton', 'Adelaida', 'Adelanto', 'Adin', 'Agoura', 'Agoura Hills', 'Agua Caliente', 'Agua Caliente Springs', 'Agua Dulce', 'Aguanga', 'Ahwahnee', 'Al Tahoe', 'Alameda', 'Alamo', 'Albany',
  'Alberhill (Lake Elsinore)', 'Albion', 'Alderpoint', 'Alhambra', 'Aliso Viejo', 'Alleghany', 'Almaden Valley', 'Almanor', 'Almondale', 'Alondra', 'Alpaugh', 'Alpine', 'Alta', 'Alta Loma (Rancho Cucamonga)', 'Altadena',
  'Altaville', 'Alton', 'Alturas', 'Alviso (San Jose)', 'Amador City', 'Amargosa (Death Valley)', 'Amboy', 'American Canyon', 'Anaheim', 'Anderson', 'Angels Camp', 'Angelus Oaks', 'Angwin', 'Annapolis', 'Antelope',
  'Antelope Acres', 'Antioch', 'Anza', 'Apple Valley', 'Applegate', 'Aptos', 'Arbuckle', 'Arcadia', 'Arcata', 'Argus', 'Arleta (Los Angeles)', 'Arlington (Riverside)', 'Armona', 'Army Terminal', 'Arnold', 'Aromas',
  'Arrowbear Lake', 'Arrowhead Highlands', 'Arroyo Grande', 'Artesia', 'Artois', 'Arvin', 'Ashland', 'Asti', 'Atascadero', 'Athens', 'Atherton', 'Atwater', 'Atwood', 'Auberry', 'Auburn', 'Avalon', 'Avenal', 'Avery',
  'Avila Beach', 'Azusa', 'Badger', 'Bailey', 'Baker', 'Bakersfield', 'Balboa (Newport Beach)', 'Balboa Island (Newport Beach)', 'Balboa Park (San Diego)', 'Baldwin Park', 'Ballard', 'Ballico', 'Ballroad', 'Bangor',
  'Banning', 'Banta', 'Bard', 'Barrington', 'Barstow', 'Bartlett', 'Barton', 'Base Line', 'Bass Lake', 'Bassett', 'Baxter', 'Bay Point', 'Bayside', 'Baywood Park', 'Beale A.F.B.', 'Bear River Lake', 'Bear Valley',
  'Bear Valley', 'Beaumont', 'Beckwourth', 'Bel Air Estates', 'Belden', 'Bell', 'Bell Gardens', 'Bella Vista', 'Bellflower', 'Belmont', 'Belvedere', 'Ben Lomond', 'Benicia', 'Benton', 'Berkeley', 'Bermuda Dunes',
  'Berry Creek', 'Bethel Island', 'Betteravia', 'Beverly Hills', 'Bieber', 'Big Bar', 'Big Basin', 'Big Bear City', 'Big Bear Lake', 'Big Bend', 'Big Creek', 'Big Oak Flat', 'Big Pine', 'Big River', 'Big Sur',
  'Biggs', 'Bijou', 'Biola', 'Biola College (La Mirada)', 'Birds Landing', 'Bishop', 'Black Hawk', 'Blairsden', 'Blocksburg', 'Bloomington', 'Blossom Hill', 'Blossom Valley', 'Blue Jay', 'Blue Lake', 'Blythe',
  'Bodega', 'Bodega Bay', 'Bodfish', 'Bolinas', 'Bolsa', 'Bombay Beach', 'Bonita', 'Bonny Doon', 'Bonsall', 'Boonville', 'Boron', 'Borrego Springs', 'Bostonia', 'Boulder Creek', 'Boulevard', 'Bouquet Canyon (Santa Clarita)',
  'Bowman', 'Boyes Hot Springs', 'Bradbury', 'Bradford', 'Bradley', 'Branscomb', 'Brawley', 'Brea', 'Brents Junction', 'Brentwood', 'Brentwood (Los Angeles)', 'Briceland', 'Bridgeport', 'Bridgeport', 'Bridgeville', 'Brisbane',
  'Broderick (West Sacramento)', 'Brookdale', 'Brookhurst Center', 'Brooks', 'Browns Valley', 'Brownsville', 'Bryn Mawr', 'Bryte (West Sacramento)', 'Buellton', 'Buena Park', 'Burbank', 'Burlingame', 'Burney', 'Burnt Ranch',
  'Burrel', 'Burson', 'Butte City', 'Butte Meadows', 'Buttonwillow', 'Byron', 'Cabazon', 'Cabrillo', 'Cadiz', 'Calabasas', 'Calabasas Highlands', 'Calabasas Park', 'Calexico', 'Caliente', 'California City', 'California Hot Springs',
  'California Valley', 'Calimesa', 'Calipatria', 'Calistoga', 'Callahan', 'Calpella', 'Calpine', 'Calwa', 'Camarillo', 'Cambria', 'Cambrian Park', 'Cameron Park', 'Camino', 'Camp Beale', 'Camp Connell', 'Camp Curry', 'Camp Kaweah',
  'Camp Meeker', 'Camp Nelson', 'Camp Pendleton', 'Camp Roberts', 'Campbell', 'Campo', 'Campo Seco', 'Camptonville', 'Canby', 'Canoga Annex', 'Canoga Park (Los Angeles)', 'Cantil', 'Cantua Creek', 'Canyon', 'Canyon Country (Santa Clarita)',
  'Canyon Lake', 'Canyondam', 'Capay', 'Capistrano Beach (Dana Point)', 'Capitola', 'Cardiff By The Sea (Encinitas)', 'Cardwell', 'Carlotta', 'Carlsbad', 'Carmel', 'Carmel Rancho', 'Carmel Valley', 'Carmichael', 'Carnelian Bay', 'Carpinteria',
  'Carson', 'Cartago', 'Caruthers', 'Casitas Springs', 'Casmalia', 'Caspar', 'Cassel', 'Castaic', 'Castella', 'Castle A.F.B.', 'Castro Valley', 'Castroville', 'Cathedral City', 'Catheys Valley', 'Cayucos', 'Cazadero', 'Cecilville', 'Cedar',
  'Cedar Crest', 'Cedar Glen', 'Cedar Ridge', 'Cedarpines Park', 'Cedarville', 'Central Valley', 'Century City', 'Ceres', 'Cerritos', 'Challenge', 'Chambers Lodge', 'Charter Oak', 'Chatsworth (Los Angeles)', 'Cherry Valley', 'Chester',
  'Chicago Park', 'Chico', 'Chilcoot', 'China Lake NWC (Ridgecrest)', 'Chinese Camp', 'Chino', 'Chino Hills', 'Chiriaco Summit', 'Cholame', 'Chowchilla', 'Chualar', 'Chula Vista', 'Cima', 'Citrus Heights', 'City of Commerce',
  'City of Industry', 'City Terrace', 'Claremont', 'Clarksburg', 'Clayton', 'Clear Creek', 'Clearlake', 'Clearlake Highlands (Clearlake)', 'Clearlake Oaks', 'Clearlake Park (Clearlake)', 'Clements', 'Clinter', 'Clio',
  'Clipper Mills', 'Cloverdale', 'Clovis', 'Coachella', 'Coalinga', 'Coarsegold', 'Cobb', 'Cohasset', 'Cole', 'Coleville', 'Colfax', 'College City', 'College Grove Center', 'Colma', 'Coloma', 'Colorado', 'Colton',
  'Columbia', 'Colusa', 'Commerce', 'Comptche', 'Compton', 'Concord', 'Cool', 'Copperopolis', 'Corcoran', 'Cornell', 'Corning', 'Corona', 'Corona Del Mar (Newport Beach)', 'Coronado', 'Corralitos', 'Corte Madera',
  'Coso Junction', 'Costa Mesa', 'Cotati', 'Coto De Caza', 'Cottonwood', 'Coulterville', 'Courtland', 'Covelo', 'Covina', 'Cowan Heights', 'Coyote', 'Crannell', 'Crenshaw', 'Crescent City', 'Crescent Mills',
  'Cressey', 'Crest', 'Crest Park', 'Cresta Blanca', 'Crestline', 'Creston', 'Crockett', 'Cromberg', 'Cross Roads', 'Crowley Lake', 'Crows Landing', 'Cucamonga (Rancho Cucamonga)', 'Cudahy', 'Culver City',
  'Cummings', 'Cupertino', 'Curry Village', 'Cutler', 'Cutten', 'Cuyama', 'Cypress', 'Daggett', 'Dairy Farm', 'Daly City', 'Dana Point', 'Danville', 'Dardanelle', 'Darwin', 'Davenport', 'Davis',
  'Davis Creek', 'Death Valley', 'Death Valley Junction', 'Deer Park', 'Del Kern (Bakersfield)', 'Del Mar', 'Del Mar Heights (Morro Bay)', 'Del Monte Park (Monterey)', 'Del Rey', 'Del Rey Oaks',
  'Del Rosa', 'Del Sur', 'Delano', 'Deleven', 'Delhi', 'Denair', 'Denny', 'Descanso', 'Desert Center', 'Desert Hot Springs', 'Di Giorgio', 'Diablo', 'Diamond Bar', 'Diamond Springs', 'Dillon Beach',
  'Dinkey Creek', 'Dinuba', 'Discovery Bay', 'Dixon', 'Dobbins', 'Dogtown', 'Dollar Ranch', 'Dorris', 'Dos Palos', 'Dos Rios', 'Douglas City', 'Douglas Flat', 'Downey', 'Downieville', 'Doyle',
  'Drytown', 'Duarte', 'Dublin', 'Ducor', 'Dulzura', 'Duncans Mills', 'Dunlap', 'Dunnigan', 'Dunsmuir', 'Durham', 'Dutch Flat', 'Eagle Mountain', 'Eagle Rock (Los Angeles)', 'Eagleville',
  'Earlimart', 'Earp', 'East Highlands (Highland)', 'East Irvine (Irvine)', 'East Los Angeles', 'East Lynwood (Lynwood)', 'East Nicolaus', 'East Palo Alto', 'East Porterville', 'East Rancho Dominguez',
  'East San Pedro (Los Angeles)', 'Eastgate', 'Easton', 'Eastside', 'Eastvale', 'Echo Lake', 'Echo Park (Los Angeles)', 'Edgemont (Moreno Valley)', 'Edgewood', 'Edison', 'Edwards', 'Edwards A.F.B.',
  'El Cajon', 'El Centro', 'El Cerrito', 'El Dorado', 'El Dorado Hills', 'El Granada', 'El Macero', 'El Modena', 'El Monte', 'El Nido', 'El Portal', 'El Segundo', 'El Sobrante', 'El Toro (Lake Forest)',
  'El Toro M.C.A.S.', 'El Verano', 'El Viejo', 'Eldridge', 'Elizabeth Lake', 'Elk', 'Elk Creek', 'Elk Grove', 'Elmira', 'Elmwood', 'Elverta', 'Emerald Hills (Redwood City)', 'Emeryville', 'Emigrant Gap',
  'Empire', 'Encinitas', 'Encino (Los Angeles)', 'Enterprise', 'Escalon', 'Escondido', 'Esparto', 'Essex', 'Etiwanda (Rancho Cucamonga)', 'Etna', 'Ettersburg', 'Eureka', 'Exeter', 'Fair Oaks', 'Fairfax',
  'Fairfield', 'Fairmount', 'Fall River Mills', 'Fallbrook', 'Fallbrook Junction', 'Fallen Leaf', 'Fallon', 'Fancher', 'Farmersville', 'Farmington', 'Fawnskin', 'Feather Falls', 'Fellows', 'Felton',
  'Fenner', 'Fernbridge (Fortuna)', 'Ferndale', 'Fiddletown', 'Fields Landing', 'Fig Garden Village (Fresno)', 'Fillmore', 'Finley', 'Firebaugh', 'Fish Camp', 'Five Points', 'Flinn Springs',
  'Flintridge (LaCanada/ Flintridge)', 'Florence', 'Floriston', 'Flournoy', 'Folsom', 'Fontana', 'Foothill Ranch', 'Forbestown', 'Forest Falls', 'Forest Glen', 'Forest Knolls', 'Forest Park',
  'Forest Ranch','Foresthill', 'Forestville', 'Forks of Salmon', 'Fort Bidwell', 'Fort Bragg', 'Fort Dick', 'Fort Irwin', 'Fort Jones', 'Fort Ord (Seaside)', 'Fort Seward', 'Fortuna', 'Foster City',
  'Fountain Valley', 'Fowler', 'Frazier Park', 'Freedom', 'Freedom (Watsonville)', 'Freeport', 'Freestone', 'Fremont', 'French Camp', 'French Gulch', 'Freshwater', 'Fresno', 'Friant', 'Friendly Valley (Santa Clarita)',
  'Frontera', 'Fullerton', 'Fulton', 'Galt', 'Garberville', 'Garden Grove', 'Garden Valley', 'Gardena', 'Garey', 'Garnet', 'Gasquet', 'Gaviota', 'Gazelle', 'George A.F.B.', 'Georgetown', 'Gerber', 'Geyserville',
  'Giant Forest', 'Gillman Hot Springs', 'Gilroy', 'Glassell Park (Los Angeles)', 'Glen Avon', 'Glen Ellen', 'Glenburn', 'Glencoe', 'Glendale', 'Glendora', 'Glenhaven', 'Glenn', 'Glennville', 'Gold River (Rancho Cordova)',
  'Gold Run', 'Golden Hills', 'Goleta', 'Gonzales', 'Goodyears Bar', 'Gorman', 'Goshen', 'Government Island', 'Graeagle', 'Granada Hills (Los Angeles)', 'Grand Terrace', 'Granite Bay', 'Grass Valley', 'Graton', 'Green Valley',
  'Green Valley Lake', 'Greenacres', 'Greenbrae (Larkspur)', 'Greenfield', 'Greenview', 'Greenville', 'Greenwood', 'Grenada', 'Gridley', 'Grimes', 'Grizzly Flats', 'Groveland', 'Grover Beach', 'Guadalupe', 'Gualala', 'Guasti (Ontario)',
  'Guatay', 'Guerneville', 'Guinda', 'Gustine', 'Hacienda Heights', 'Halcyon', 'Half Moon Bay', 'Hamilton A.F.B. (Novato)', 'Hamilton City', 'Hanford', 'Happy Camp', 'Harbison Canyon', 'Harbor City (Los Angeles)', 'Harmony', 'Harris',
  'Hat Creek', 'Hathaway Pines', 'Havasu Lake', 'Hawaiian Gardens', 'Hawthorne', 'Hayfork', 'Hayward', 'Hazard', 'Healdsburg', 'Heber', 'Helena', 'Helendale', 'Helm', 'Hemet', 'Herald', 'Hercules', 'Herlong', 'Hermosa Beach', 'Herndon',
  'Hesperia', 'Heyer', 'Hickman', 'Hidden Hills', 'Highgrove', 'Highland', 'Highland Park (Los Angeles)', 'Highway City (Fresno)', 'Hillcrest (San Diego)', 'Hillsborough', 'Hillsdale (San Mateo)', 'Hilmar', 'Hilt', 'Hinkley', 'Hobergs',
  'Hollister', 'Hollywood (Los Angeles)', 'Holmes', 'Holt', 'Holtville', 'Holy City', 'Homeland', 'Homestead', 'Homestead', 'Homewood', 'Honby', 'Honeydew', 'Hood', 'Hoopa', 'Hope Valley (Forest Camp)', 'Hopland', 'Hornbrook', 'Hornitos',
  'Horse Creek', 'Horse Lake', 'Hughson', 'Hume', 'Huntington', 'Huntington Beach', 'Huntington Lake', 'Huntington Park', 'Huron', 'Hyampom', 'Hyde Park (Los Angeles)', 'Hydesville', 'Idria', 'Idyllwild', 'Ignacio (Novato)', 'Igo',
  'Imola (Napa)', 'Imperial', 'Imperial Beach', 'Independence', 'Indian Wells', 'Indio', 'Industry', 'Inglewood', 'Inverness', 'Inyo', 'Inyokern', 'Ione', 'Iowa Hill', 'Irvine', 'Irwindale', 'Isla Vista', 'Island Mountain', 'Isleton',
  'Ivanhoe', 'Ivanpah', 'Jackson', 'Jacumba', 'Jamacha', 'Jamestown', 'Jamul', 'Janesville', 'Jenner', 'Johannesburg', 'Johnsondale', 'Johnstonville', 'Johnstown', 'Jolon', 'Joshua Tree', 'Julian', 'Junction City', 'June Lake',
  'Juniper', 'Jurupa Valley', 'Kagel Canyon', 'Kaweah', 'Keddie', 'Keeler', 'Keene', 'Kelsey', 'Kelseyville', 'Kelso', 'Kensington', 'Kentfield', 'Kenwood', 'Kerman', 'Kernville', 'Keswick', 'Kettleman City', 'Keyes',
  'King City', 'Kings Beach', 'Kings Canyon National Park', 'Kingsburg', 'Kinyon', 'Kirkwood', 'Kit Carson', 'Klamath', 'Klamath River', 'Kneeland', 'Knights Ferry', 'Knights Landing', 'Knightsen', 'Korbel', 'Korbel',
  'Kyburz', 'L.A. Airport (Los Angeles)', 'La Canada- Flintridge', 'La Crescenta', 'La Cresta Village', 'La Grange', 'La Habra', 'La Habra Heights', 'La Honda', 'La Jolla (San Diego)', 'La Mesa', 'La Mirada',
  'La Palma', 'La Porte', 'La Puente', 'La Quinta', 'La Selva Beach', 'La Verne', 'La Vina', 'Ladera', 'Ladera Heights', 'Ladera Ranch', 'Lafayette', 'Laguna Beach', 'Laguna Hills', 'Laguna Niguel',
  'Laguna Woods', 'Lagunitas', 'Lake Alpine', 'Lake Arrowhead', 'Lake City', 'Lake City', 'Lake Elsinore', 'Lake Forest', 'Lake Hughes', 'Lake Isabella', 'Lake Los Angeles', 'Lake Mary', 'Lake San Marcos',
  'Lake Shastina', 'Lake Sherwood', 'Lakehead', 'Lakeport', 'Lakeshore', 'Lakeside', 'Lakeview', 'Lakeview Terrace (Los Angeles)', 'Lakewood', 'Lamont', 'Lancaster', 'Landers', 'Landscape', 'Lang',
  'Larkfield', 'Larkspur', 'Larwin Plaza', 'Lathrop', 'Laton', 'Lawndale', 'Laws', 'Laytonville', 'Le Grand (Also Legrand)', 'Lebec', 'Lee Vining', 'Leggett', 'Leisure World', 'Leisure World (Seal Beach)',
  'Lemon Grove', 'Lemoncove', 'Lemoore', 'Lennox', 'Lenwood', 'Leona Valley', 'Leucadia (Encinitas)', 'Lewiston', 'Liberty Farms', 'Likely', 'Lincoln', 'Lincoln Acres', 'Lincoln Heights (Los Angeles)', 'Lincoln Village', 'Linda', 'Linden', 'Lindsay', 'Linnell',
  'Litchfield', 'Little Lake', 'Little Norway', 'Little Valley', 'Littleriver', 'Littlerock (Also Little Rock)', 'Live Oak', 'Live Oak ', 'Livermore', 'Livingston', 'Llano', 'Loch Lomond', 'Locke', 'Lockeford', 'Lockheed', 'Lockwood', 'Lodi', 'Loleta', 'Loma Linda',
  'Loma Mar', 'Loma Rica', 'Lomita', 'Lompoc', 'London', 'Lone Pine', 'Long Barn', 'Long Beach', 'Longview', 'Lookout', 'Loomis', 'Lorre Estates', 'Los Alamitos', 'Los Alamos', 'Los Altos', 'Los Altos Hills', 'Los Angeles', 'Los Banos', 'Los Gatos', 'Los Molinos',
  'Los Nietos', 'Los Olivos', 'Los Osos', 'Los Padres', 'Los Serranos (Chino Hills)', 'Lost Hills', 'Lost Lake', 'Lotus', 'Lower Lake', 'Loyalton', 'Lucerne', 'Lucerne Valley', 'Lucia', 'Ludlow', 'Lugo', 'Lynwood', 'Lytle Creek', 'Macdoel', 'Maclay', 'Mad River',
  'Madeline', 'Madera', 'Madison', 'Magalia', 'Malaga', 'Malibu', 'Mammoth Lakes', 'Manhattan Beach', 'Manteca', 'Manton', 'Manzanita Lake', 'Mar Vista', 'Marcelina', 'March A.F.B.', 'Mare Island (Vallejo)', 'Maricopa', 'Marin City', 'Marina', 'Marina Del Rey',
  'Marine Corps (Twentynine Palms)', 'Mariner', 'Mariposa', 'Markleeville', 'Marsh Manor', 'Marshall', 'Martell', 'Martinez', 'Marysville', 'Mather', 'Mather ', 'Maxwell', 'Maywood', 'McArthur', 'McClellan', 'McCloud', 'McFarland', 'McKinleyville', 'McKittrick',
  'Mead Valley', 'Meadow Valley', 'Meadow Vista', 'Meadowbrook', 'Mecca', 'Meeks Bay', 'Meiners Oaks', 'Mendocino', 'Mendota', 'Menifee', 'Menlo Park', 'Mentone', 'Merced', 'Meridian', 'Mettler', 'Meyers', 'Middletown', 'Midland', 'Midpines', 'Midway City',
  'Milford', 'Mill Creek', 'Mill Valley', 'Millbrae', 'Millville', 'Milpitas', 'Mineral', 'Mineral King', 'Mint Canyon', 'Mira Loma', 'Mira Vista', 'Miracle Hot Springs', 'Miramar (San Diego)', 'Miramonte', 'Miranda', 'Mission Hills (Los Angeles)', 'Mission Viejo',
  'Mi-Wuk Village', 'Moccasin', 'Modesto', 'Moffett Field', 'Mojave', 'Mokelumne Hill', 'Monarch Beach (Dana Point)', 'Moneta', 'Mono Hot Springs', 'Mono Lake', 'Monolith', 'Monrovia', 'Monta Vista', 'Montague', 'Montalvo (Ventura)', 'Montara', 'Montclair', 'Monte Rio',
  'Monte Sereno', 'Montebello', 'Montecito', 'Monterey', 'Monterey Bay Academy', 'Monterey Park', 'Montgomery Creek', 'Montrose', 'Mooney', 'Moonridge', 'Moorpark', 'Moraga', 'Moreno Valley', 'Morgan Hill', 'Morongo Valley', 'Morro Bay', 'Morro Plaza', 'Moss Beach',
  'Moss Landing', 'Mount Hamilton', 'Mount Hebron', 'Mount Hermon', 'Mount Laguna', 'Mount Shasta', 'Mount Wilson', 'Mountain Center', 'Mountain Mesa', 'Mountain Pass', 'Mountain Ranch', 'Mountain View', 'Mt. Aukum', 'Mt. Baldy', 'Murphys', 'Murrieta', 'Muscoy',
  'Myers Flat', 'Napa', 'Naples', 'Nashville', 'National City', 'Naval (Port Hueneme)', 'Naval (San Diego)', 'Naval Air Station (Alameda)', 'Naval Air Station (Coronado)', 'Naval Air Station (Lemoore)', 'Naval Hospital (Oakland)', 'Naval Hospital (San Diego)', 'Naval Supply Center (Oakland)', 'Naval Training Center (San Diego)', 'Navarro', 'Needles', 'Nelson', 'Nevada City', 'New Almaden', 'New Cuyama', 'New Idria', 'Newark', 'Newberry', 'Newberry Springs', 'Newbury Park (Thousand Oaks)',
  'Newcastle', 'Newhall (Santa Clarita)', 'Newman', 'Newport Beach', 'Nicasio', 'Nice', 'Nicolaus', 'Niland', 'Nipomo', 'Nipton', 'Norco', 'Norden', 'North Edwards', 'North Fork', 'North Gardena', 'North Highlands', 'North Hills (Los Angeles)', 'North Hollywood (Los Angeles)', 'North Palm Springs', 'North San Juan', 'North Shore', 'Northridge (Los Angeles)', 'Norton A.F.B. (San Bernardino)', 'Norwalk', 'Novato', 'Nubieber', 'Nuevo', 'Nyeland Acres', 'Oak Park', 'Oak Run', 'Oak View',
  'Oakdale', 'Oakhurst', 'Oakland', 'Oakley', 'Oakville', 'Oasis', 'Oban', "O'Brien", 'Occidental', 'Oceano', 'Oceanside', 'Ocotillo', 'Ocotillo Wells', 'Oildale', 'Ojai', 'Olancha', 'Old Station', 'Olema', 'Olinda', 'Olive View (Los Angeles)', 'Olivehurst', 'Olivenhain (Encinitas)', 'Olympic Valley', 'Omo Ranch', "O'Neals", 'Ono', 'Ontario', 'Onyx', 'Opal Cliffs', 'Orange', 'Orange Cove', 'Orangevale', 'Orcutt', 'Ordbend', 'Oregon House', 'Orick', 'Orinda', 'Orland', 'Orleans',
  'Oro Grande', 'Orosi', 'Oroville', 'Otay (Chula Vista)', 'Oxnard', 'Pacheco', 'Pacific Grove', 'Pacific House', 'Pacific Palisades (Los Angeles)', 'Pacifica', 'Pacoima (Los Angeles)', 'Paicines', 'Pajaro', 'Pala', 'Palermo', 'Pallett', 'Palm City', 'Palm City (San Diego)', 'Palm Desert', 'Palm Springs', 'Palmdale', 'Palo Alto', 'Palo Cedro', 'Palo Verde', 'Palomar Mountain', 'Palos Verdes Estates', 'Palos Verdes/Peninsula', 'Panorama City (Los Angeles)', 'Paradise',
  'Paramount', 'Parker Dam', 'Parkfield', 'Parlier', 'Pasadena', 'Paskenta', 'Paso Robles', 'Patterson', 'Patton', 'Pauma Valley', 'Paynes Creek', 'Pearblossom', 'Pearland', 'Pebble Beach', 'Pedley', 'Peninsula Village', 'Penn Valley', 'Penngrove', 'Penryn', 'Pepperwood', 'Permanente', 'Perris', 'Perry (Whittier)', 'Pescadero', 'Petaluma', 'Petrolia', 'Phelan', 'Phillipsville', 'Philo', 'Pico Rivera', 'Piedmont', 'Piedra', 'Piercy', 'Pilot Hill', 'Pine Grove', 'Pine Valley',
  'Pinecrest', 'Pinedale (Fresno)', 'Pinetree', 'Pinole', 'Pinon Hills', 'Pioneer', 'Pioneertown', 'Piru', 'Pismo Beach', 'Pittsburg', 'Pixley', 'Placentia', 'Placerville', 'Plainview', 'Planada', 'Plaster City', 'Platina', 'Playa Del Rey (Los Angeles)', 'Pleasant Grove', 'Pleasant Hill', 'Pleasanton', 'Plymouth', 'Point Arena', 'Point Mugu', 'Point Pittsburg (Pittsburg)', 'Point Reyes Station', 'Pollock Pines', 'Pomona', 'Pond', 'Pondosa', 'Pope Valley', 'Poplar', 'Port Costa','Port Hueneme', 'Porter Ranch (Los Angeles)', 'Porterville', 'Portola', 'Portola Valley', 'Portuguese Bend (Rancho Palos Verdes)', 'Posey', 'Potrero', 'Potter Valley', 'Poway', 'Prather', 'Presidio (San Francisco)', 'Presidio of Monterey (Monterey)', 'Priest Valley', 'Princeton', 'Proberta', 'Project City', 'Prunedale', 'Pt. Dume', 'Pulga', 'Pumpkin Center', 'Quail Valley', 'Quartz Hill', 'Quincy', 'Rackerby', 'Rail Road Flat', 'Rainbow', 'Raisin City', 'Ramona', 'Ranchita',
  'Rancho Bernardo (San Diego)', 'Rancho California', 'Rancho Cordova ', 'Rancho Cucamonga', 'Rancho Dominguez', 'Rancho Mirage', 'Rancho Murieta', 'Rancho Palos Verdes', 'Rancho Park (Los Angeles)', 'Rancho Santa Fe', 'Rancho Santa Margarita', 'Randsburg', 'Ravendale', 'Ravenna', 'Raymond', 'Red Bluff', 'Red Mountain', 'Red Top', 'Redcrest', 'Redding', 'Redlands', 'Redondo Beach', 'Redway', 'Redwood City', 'Redwood Estates', 'Redwood Valley', 'Reedley', 'Refugio Beach', 'Represa (Folsom Prison)',
  'Requa', 'Rescue', 'Reseda (Los Angeles)', 'Rheem Valley (Moraga)', 'Rialto', 'Richardson Grove', 'Richardson Springs', 'Richfield', 'Richgrove', 'Richmond', 'Richvale', 'Ridgecrest', 'Rimforest', 'Rimpau (Los Angeles)', 'Rio Bravo (Bakersfield)', 'Rio Del Mar', 'Rio Dell', 'Rio Linda', 'Rio Nido', 'Rio Oso', 'Rio Vista', 'Ripley', 'Ripon', 'River Pines', 'Riverbank', 'Riverdale', 'Riverside', 'Robbins', 'Rocklin', 'Rodeo', 'Rohnert Park', 'Rohnerville', 'Rolling Hills', 'Rolling Hills Estates',
  'Romoland', 'Rosamond', 'Rose Bowl (Pasadena)', 'Roseland', 'Rosemead', 'Roseville', 'Ross', 'Rossmoor', 'Rough and Ready', 'Round Mountain', 'Rowland Heights', 'Royal Oaks', 'Rubidoux', 'Ruby Valley', 'Rumsey', 'Running Springs', 'Ruth', 'Rutherford', 'Ryde', 'Sacramento', 'Saint Helena', 'Salida', 'Salinas', 'Salton City', 'Salyer', 'Samoa', 'San Andreas', 'San Anselmo', 'San Ardo', 'San Benito', 'San Bernardino', 'San Bruno', 'San Carlos (San Diego)', 'San Carlos', 'San Clemente', 'San Diego',
  'San Dimas', 'San Fernando', 'San Francisco', 'San Gabriel', 'San Geronimo', 'San Gregorio', 'San Jacinto', 'San Joaquin', 'San Jose', 'San Juan Bautista', 'San Juan Capistrano', 'San Juan Plaza (San Juan Capistrano)', 'San Leandro', 'San Lorenzo', 'San Lucas', 'San Luis Obispo', 'San Luis Rey (Oceanside)', 'San Marcos', 'San Marino', 'San Martin', 'San Mateo', 'San Miguel', 'San Pablo', 'San Pedro (Los Angeles)', 'San Quentin', 'San Rafael', 'San Ramon', 'San Simeon', 'San Tomas', 'San Ysidro (San Diego)',
  'Sand City', 'Sanger', 'Santa Ana', 'Santa Barbara', 'Santa Clara', 'Santa Clarita', 'Santa Cruz', 'Santa Fe Springs', 'Santa Margarita', 'Santa Maria', 'Santa Monica', 'Santa Nella', 'Santa Paula', 'Santa Rita Park', 'Santa Rosa', 'Santa Rosa Valley', 'Santa Ynez', 'Santa Ysabel', 'Santee', 'Saratoga', 'Saticoy', 'Sattley', 'Saugus (Santa Clarita)', 'Sausalito', 'Sawtelle (Los Angeles)', 'Sawyers Bar', 'Scotia', 'Scott Bar', 'Scotts Valley', 'Sea Ranch', 'Seabright (Santa Cruz)', 'Seal Beach', 'Seaside',
  'Sebastopol', 'Seeley', 'Seiad Valley', 'Selby', 'Selma', 'Seminole Hot Springs', 'Sepulveda (Los Angeles)', 'Sequoia National Park', 'Shafter', 'Shandon', 'Sharpe Army Depot', 'Shasta', 'Shasta Lake', 'Shaver Lake', 'Sheepranch', 'Shell Beach (Pismo Beach)', 'Sheridan', 'Sherman Island', 'Sherman Oaks (Los Angeles)', 'Sherwin Plaza', 'Shingle Springs', 'Shingletown', 'Shively', 'Shore Acres', 'Shoshone', 'Sierra City', 'Sierra Madre', 'Sierraville', 'Signal Hill', 'Silver Lake', 'Silverado Canyon',
  'Simi Valley', 'Sisquoc', 'Sites', 'Sky Valley', 'Skyforest', 'Sleepy Valley', 'Sloat', 'Sloughhouse', 'Smartsville', 'Smith River', 'Smithflat', 'Smoke Tree (Palm Springs)', 'Smoke Tree (Twentynine Palms)', 'Snelling', 'Soda Springs', 'Solana Beach', 'Soledad', 'Solemint', 'Solvang', 'Somerset', 'Somes Bar', 'Somis', 'Sonoma', 'Sonora', 'Soquel', 'Soulsbyville', 'South Dos Palos', 'South El Monte', 'South Fork', 'South Gate', 'South Laguna (Laguna Beach)', 'South Lake Tahoe', 'South Pasadena',
  'South San Francisco', 'South Shore (Alameda)', 'South Whittier', 'Spanish Flat', 'Spreckels', 'Spring Garden', 'Spring Valley', 'Springville', 'Spyrock', 'Squaw Valley', 'St. Helena', 'Standard', 'Standish', 'Stanford', 'Stanislaus', 'Stanton', 'Steele Park', 'Stevenson Ranch', 'Stevinson', 'Stewarts Point', 'Stinson Beach', 'Stirling City', 'Stockton', 'Stonyford', 'Storrie', 'Stratford', 'Strathmore', 'Strawberry', 'Strawberry Valley', 'Studio City (Los Angeles)', 'Sugarloaf', 'Suisun City',
  'Sulphur Springs', 'Sultana', 'Summerland', 'Summit', 'Summit City', 'Sun City', 'Sun Valley (Los Angeles)', 'Sunland (Los Angeles)', 'Sunnymead (Moreno Valley)', 'Sunnyside', 'Sunol', 'Sunset Beach', 'Sunset Whitney Ranch', 'Surfside (Seal Beach)', 'Susanville', 'Sutter', 'Sutter Creek', 'Swall Meadows (Bishop)', 'Sylmar (Los Angeles)', 'Taft', 'Tagus Ranch', 'Tahoe City', 'Tahoe Paradise', 'Tahoe Valley', 'Tahoe Vista', 'Tahoma', 'Talmage', 'Tamal (San Quentin)', 'Tarzana (Los Angeles)',
  'Taylorsville', 'Tecate', 'Tecopa', 'Tehachapi', 'Tehama', 'Temecula', 'Temple City', 'Templeton', 'Terminal Island (Los Angeles)', 'Termo', 'Terra Bella', 'Thermal', 'Thornton', 'Thousand Oaks', 'Thousand Palms', 'Three Rivers', 'Tiburon', 'Tierra Del Sol', 'Tierrasanta (San Diego)', 'Tipton', 'Tollhouse', 'Toluca Lake (Los Angeles)', 'Tomales', 'Toms Place', 'Topanga (Los Angeles)', 'Topanga Park (Los Angeles)', 'Topaz', 'Torrance', 'Town Center', 'Trabuco Canyon', 'Tracy', 'Tranquillity', 'Traver',
  'Travis A.F.B. (Fairfield)', 'Tres Pinos', 'Trinidad', 'Trinity Center', 'Trona', 'Trowbridge', 'Truckee', 'Tujunga (Los Angeles)', 'Tulare', 'Tulelake', 'Tuolumne', 'Tuolumne Meadows', 'Tupman', 'Turlock', 'Tustin', 'Twain', 'Twain Harte', 'Twentynine Palms', 'Twin Bridges', 'Twin Peaks', 'Two Rock Coast Guard Station', 'U.S. Naval Postgrad School (Monterey)', 'Ukiah', 'Union City', 'Universal City', 'University', 'University Park (Irvine)', 'Upland', 'Upper Lake/ Upper Lake Valley', 'Vacaville',
  'Val Verde Park', 'Valencia (Santa Clarita)', 'Valinda', 'Vallecito', 'Vallejo', 'Valley Center', 'Valley Fair', 'Valley Ford', 'Valley Home', 'Valley Springs', 'Valley Village', 'Valyermo', 'Van Nuys (Los Angeles)', 'Vandenberg A.F.B', 'Vasquez Rocks', 'Venice (Los Angeles)', 'Ventucopa', 'Ventura', 'Verdugo City (Glendale)', 'Vernalis', 'Vernon', "Veteran's Hospital (Los Angeles)", 'Victor', 'Victorville', 'Vidal', 'View Park', 'Villa Grande', 'Villa Park', 'Vina', 'Vincent', 'Vineburg', 'Vinton',
'Virgilia', 'Visalia', 'Vista', 'Vista Park', 'Volcano', 'Volta', 'Wallace', 'Walnut', 'Walnut Creek', 'Walnut Grove', 'Walnut Park', 'Warm Springs (Fremont)', 'Warner Springs', 'Wasco', 'Waterford', 'Watsonville', 'Watts', 'Waukena', 'Wawona', 'Weaverville', 'Weed', 'Weimar', 'Weldon', 'Wendel', 'Weott', 'West Covina', 'West Hills (Los Angeles)', 'West Hollywood', 'West Los Angeles (Los Angeles)', 'West Pittsburg', 'West Point', 'West Sacramento', 'Westchester (Los Angeles)', 'Westend', 'Westhaven',
  'Westlake (Los Angeles)', 'Westlake Village', 'Westlake Village (Thousand Oaks)', 'Westley', 'Westminster', 'Westmorland', 'Westport', 'Westside', 'Westwood', 'Westwood (Los Angeles)', 'Wheatland', 'Wheeler Ridge', 'Whiskeytown', 'Whispering Pines', 'White Pines', 'Whitethorn', 'Whitewater', 'Whitlow', 'Whitmore', 'Whittier', 'Wildomar', 'Wildwood', 'Williams', 'Willits', 'Willow Creek', 'Willow Ranch', 'Willowbrook', 'Willows', 'Wilmington (Los Angeles)', 'Wilseyville', 'Wilsona Gardens', 'Wilton',
  'Winchester', 'Windsor', 'Windsor Hills', 'Winnetka (Los Angeles)', 'Winterhaven', 'Winters', 'Winton', 'Wishon', 'Witter Springs', 'Wofford Heights', 'Woodacre', 'Woodbridge', 'Woodfords', 'Woodlake', 'Woodland', 'Woodland Hills (Los Angeles)', 'Woodleaf', 'Woodside', 'Woodville', 'Woody', 'Wrightwood', 'Yankee Hill', 'Yermo', 'Yettem', 'Yolo', 'Yorba Linda', 'Yorkville', 'Yosemite Lodge', 'Yosemite National Park', 'Yountville', 'Yreka', 'Yuba City', 'Yucaipa', 'Yucca Valley', 'Zamora', 'Zenia',
  'Del Monte Grove (Monterey)', 'Fort Ord', 'Fort Ord (Marina)', 'Shadow Hills (Los Angeles)', 'Other'];



  registrationForm: FormGroup;
  constructor(private modelController:ModalController, 
  	// private fb: FormBuilder, 
    public remoteService: RemoteService,
    public alertController: AlertController,
    private router : Router) { 

    this.registrationForm = new FormGroup({
      // Validators.required
      addressTypeSelect:new FormControl('', []),
      companyName:new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      firstName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.pattern('^[a-zA-Z]+$')]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.pattern('^[a-zA-Z]+$')]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.minLength(4), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      addressOne: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(255)]),
      addressTwo: new FormControl('', [Validators.minLength(5), Validators.maxLength(255)]),
      countyrName:new FormControl('', [Validators.required]),
      
      stateSelect:new FormControl('', []),
      stateText:new FormControl('', [Validators.minLength(2), Validators.maxLength(255)]),

      citySelect:new FormControl('', []),
      cityText:new FormControl('', [Validators.minLength(2), Validators.maxLength(255)]),

      zipCode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(6)]),
      phoneNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      faxNumber: new FormControl( [Validators.minLength(9), Validators.maxLength(10)]),
      defaultType: new FormControl(false)
      //jobStatus: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
      //jobSectore: new FormControl('', [Validators.required]),
      //isTosRead: new FormControl(false, [Validators.requiredTrue])
      });

      if(this.remoteService.addressEditPopup){
        // this.registrationForm.addControl('addressTypeSelect', new FormControl('', [Validators.required]));
      // }else{
        setTimeout(()=>{
        // this.registrationForm.removeControl('addressTypeSelect');
        },3000);
      }
  }

  // ngAfterViewInit() {
  //   alert( 'all done loading :)'); // needs to be rendered the DOM
  // }
  ngOnInit() {
  // async ngOnInit() {
    // this.remoteService.getCountryList();
    // this.remoteService.getStateByCountry();
    // this.remoteService.getCityByState();
    this.remoteService.add_to_all_address = false;
    if(this.remoteService.addressEditPopup != true){
      this.remoteService.contact_company="";
      this.remoteService.contact_email="";
      this.remoteService.contact_fax="";
      this.remoteService.contact_state="";
      this.remoteService.contact_city="";
      this.remoteService.contact_country="";
      this.remoteService.address_type_id="";
      this.remoteService.contact_first_name="";
      this.remoteService.contact_last_name="";
      this.remoteService.contact_address1="";
      this.remoteService.contact_address2="";
      this.remoteService.contact_zipcode="";
      this.remoteService.contact_phone="";
      this.remoteService.default_add_address=0;
      this.remoteService.add_to_all_address = false;
      this.defaultAddressCheck = false;
    }
    
    //this.remoteService.contact_country = this.remoteService.defaultCountry;
    if (!this.remoteService.addressEditPopup){
      this.remoteService.contact_country = this.remoteService.defaultCountry;
      this.remoteService.contact_state = this.remoteService.defaultState;
      this.remoteService.contact_city = this.remoteService.defaultCity;
    }

    if (this.remoteService.addressEditPopup) {
      // console.log(this.remoteService.contact_city)
      // console.log(this.showCityInputText)
      if(this.remoteService.contact_country == "United States"){
        if (this.remoteService.contact_state == "California" || this.remoteService.contact_state == "CA") {
          this.showCityInputText = true;
        }else{
          this.showCityInputText = false;
        }
      }else{
        this.showCityInputText = false;
        this.registrationForm.controls["stateText"].setValidators([Validators.required,Validators.minLength(2)]);
      	this.registrationForm.controls["cityText"].setValidators([Validators.required,Validators.minLength(2)]);
      }

      this.defaultAddressCheck = this.remoteService.default_add_address=='1'?true:false;
    }
  }
  validation_messages = {
    'addressType': [
      // { type: 'required', message: 'address type is required.' }
    ],
    'companyName': [
      { type: 'required', message: 'company name is required.' },
      { type: 'minlength', message: 'company name must be at least 3 characters long.' },
      { type: 'maxlength', message: 'company name cannot be more than 100 characters long.' },
      { type: 'pattern', message: 'Your company name must contain only letters.' }
    ],
    'firstName': [
        { type: 'required', message: 'first name is required.' },
        { type: 'minlength', message: 'first name must be at least 1 characters long.' },
        { type: 'maxlength', message: 'first name cannot be more than 100 characters long.' },
        { type: 'pattern', message: 'Your first name must contain only letters.' }
      ],
    'lastName': [
        { type: 'required', message: 'last name is required.' },
        { type: 'minlength', message: 'last name must be at least 1 characters long.' },
        { type: 'maxlength', message: 'last name cannot be more than 100 characters long.' },
        { type: 'pattern', message: 'Your last name must contain only letters.' }
      ],
      'email': [
          { type: 'required', message: 'email is required.' },
          { type: 'minlength', message: 'email must be at least 4 characters long.' },
          { type: 'maxlength', message: 'email cannot be more than 25 characters long.' },
          { type: 'pattern', message: 'Please enter a valid email.' }
        ],
      'addressOne': [
          { type: 'required', message: 'address 1 is required.' },
          { type: 'minlength', message: 'address 1 must be at least 9 characters long.' },
          { type: 'maxlength', message: 'address 1 cannot be more than 255 characters long.' }
        ],
      'addressTwo': [
          { type: 'minlength', message: 'address 1 must be at least 5 characters long.' },
          { type: 'maxlength', message: 'address 1 cannot be more than 255 characters long.' }
        ],
      'stateText': [
        { type: 'required', message: 'state is required.' },
        { type: 'minlength', message: 'state must be at least 2 characters long.' },
        { type: 'maxlength', message: 'state cannot be more than 100 characters long.' }
      ],
      'cityText': [
        { type: 'required', message: 'city is required.' },
        { type: 'minlength', message: 'city must be at least 2 characters long.' },
        { type: 'maxlength', message: 'city cannot be more than 100 characters long.' }
      ],
      'zip': [
          { type: 'required', message: 'zipcode is required.' },
          { type: 'minlength', message: 'zipcode must be at least 5 long.' },
          { type: 'maxlength', message: 'zipcode cannot be more than 6 long.' }
        ],
      'phoneNumber': [
          { type: 'required', message: 'phone number is required.' },
          { type: 'minlength', message: 'phone number must be at least 10 numeric long.' },
          { type: 'maxlength', message: 'phone number cannot be more than 10 numeric long.' }
        ]
    }
  // compareCountry(value,option){
  //   return value === option;
  // }
  // compareState(value,option){
  //   return value === option;
  // }
  // compareCity(value,option){
  //   return value === option;
  // }
  
  
  // defaultValue(){
  //   if(this.remoteService.default_add_address == true){
  //     this.remoteService.default_add_address.val(1);
  //     alert("value is one");
  //   }else{
  //     this.remoteService.default_add_address.val(0);
  //     alert("value is zero");
  //   }
  // }

  closeModal(param){
    if (!param) {
      this.modelController.dismiss({'status': 'close','type': ''});
    }
    if (this.remoteService.addressEditPopup) {
    this.remoteService.contact_company="";
    this.remoteService.contact_email="";
    this.remoteService.contact_fax="";
    this.remoteService.contact_state="";
    this.remoteService.contact_city="";
    this.remoteService.contact_country="";
    this.remoteService.address_type_id="";
    this.remoteService.contact_first_name="";
    this.remoteService.contact_last_name="";
    this.remoteService.contact_address1="";
    this.remoteService.contact_address2="";
    this.remoteService.contact_zipcode="";
    this.remoteService.contact_phone="";
    this.remoteService.default_add_address=0;
    this.remoteService.add_to_all_address = false;
    this.remoteService.addressEditPopup = false;
    }
  }

  async addOrEditAddress(){
    this.saveAddressCount = [];
    var message = "";
    // if ((!message) && (!this.remoteService.shippingAddressPopup)
    //   &&(!this.remoteService.billingAddressPopup)
    //   &&(!this.remoteService.designerAddressPopup)
    //   &&(this.remoteService.address_type_id == null
    //   || this.remoteService.address_type_id==undefined
    //   || this.remoteService.address_type_id=="")){
    //     message = "Please select address type";
    // }
    if ((!message) && (this.remoteService.contact_company == null
      || this.remoteService.contact_company==undefined
      || this.remoteService.contact_company=="")){
        message = "Company name is required";
    }
    if ((!message) && (this.remoteService.contact_first_name == null
      || this.remoteService.contact_first_name==undefined
      || this.remoteService.contact_first_name=="")){
        message = "First name is required";
    }
    if ((!message) && (this.remoteService.contact_last_name == null
      || this.remoteService.contact_last_name==undefined
      || this.remoteService.contact_last_name=="")){
        message = "Last name is required";
    }
    if ((!message) && (this.remoteService.contact_email == null
      || this.remoteService.contact_email==undefined
      || this.remoteService.contact_email=="")){
        message = "Email is required";
    }
    
    if ((!message) && (this.remoteService.contact_address1 == null
      || this.remoteService.contact_address1==undefined
      || this.remoteService.contact_address1=="")){
        message = "Address1 is required";
    }
    if ((!message) && (this.remoteService.contact_country == null
      || this.remoteService.contact_country==undefined
      || this.remoteService.contact_country=="")){
        message = "Please select country";
    }
    if ((!message) && (this.remoteService.contact_state == null
      || this.remoteService.contact_state==undefined
      || this.remoteService.contact_state=="")){
        message = "Please select state";
    }
    if ((!message) && (this.remoteService.contact_city == null
      || this.remoteService.contact_city==undefined
      || this.remoteService.contact_city=="")){
        message = "Please select city";
    }
    if ((!message) && (this.remoteService.contact_zipcode == null
      || this.remoteService.contact_zipcode==undefined
      || this.remoteService.contact_zipcode=="")){
        message = "Zipcode is required";
    }
    if ((!message) && (this.remoteService.contact_phone == null
      || this.remoteService.contact_phone==undefined
      || this.remoteService.contact_phone=="")){
        message = "Phone number is required";
    }
    
    if (this.defaultAddressCheck) {
      this.remoteService.default_add_address = '1'; 
      // this.remoteService.default_add_address = this.remoteService.default_add_address?'0':'1'; 
    }else{
      this.remoteService.default_add_address = '0'; 
    }

    if (message){
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        subHeader: 'Validation error',
        message: message,
        buttons: ['OK']
      });
      await alert.present();
    }else{
      if (this.remoteService.addressEditPopup){
        
        // if(this.remoteService.contact_country == "United States"){
        //   // this.remoteService.addressValidation(this.remoteService.address_id).then((res: any)=>{
        //   //   if(res.addrValidation == true){
        //   //     // if (this.remoteService.address_type_id=='3') {
        //   //     //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //   //     //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //   //     // }
        //   //     setTimeout(()=>{
        //   //       this.modelController.getTop().then(v => v ? this.modelController.dismiss('save'): null);
        //   //     },1500);
        //   //   }
        //   // });
        //   this.remoteService.showLoader();
        //   this.remoteService.addressValidationCheck().then((res: any)=>{
        //     setTimeout(()=>{
        //     this.remoteService.hideLoader();
        //       if (res == null) {
        //                   this.addOrEditAddress();
        //                   return
        //                 }
        //     // this.remoteService.addressValidationCheck(this.remoteService.addressTypeIdMultiple[ati]).subscribe((res: any)=>{
        //     this.addressValidationChecking(res,this.remoteService.address_id);
        //       // if(res.addrValidation == true){
        //       //   // if (this.remoteService.address_type_id=='3') {
        //       //   //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //       //   //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //       //   // }
        //       //   setTimeout(()=>{
        //       //     this.modelController.getTop().then(v => v ? this.modelController.dismiss('save'): null);
        //         },300);
                
        //       // }
        //     });
        // }else{
        //   // if (this.remoteService.address_type_id=='3') {
        //   //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //   //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //   // }
        //   // this.remoteService.updateAddressDetails(this.remoteService.address_id);
        //   // setTimeout(()=>{
        //   //   this.modelController.getTop().then(v => v ? this.modelController.dismiss('save'): null);
        //   // },1500);

        //   this.updateNewAddressDetailsRep(this.remoteService.address_id);
        // }
        
        this.updateNewAddressDetailsRep(this.remoteService.address_id);
        // this.remoteService.updateAddressDetails(this.remoteService.address_id);
        // this.modelController.dismiss();
      }else{
        this.remoteService.addressTypeIdMultiple = this.remoteService.address_type_id;

        // if(this.remoteService.contact_country == "United States"){
        //   this.remoteService.showLoader();
        //   this.remoteService.addressValidationCheck().then((res: any)=>{
        //       setTimeout(()=>{  
        //           this.remoteService.hideLoader();
                
        //         if (res == null) {
        //                     this.addOrEditAddress();
        //                     return
        //                   }

        
        //                   this.validatingAddress(res);
        //                   // for(var ati in this.remoteService.addressTypeIdMultiple){
        //                   //     await new Promise ( resolve => setTimeout(resolve,1000));
        //                   //     this.addressValidationChecking(res,this.remoteService.addressTypeIdMultiple[ati])
        //                   // }
        //       },300);
        //   });
        // }else{
        //      this.validatingAddress('');
        //     // for(var ati in this.remoteService.addressTypeIdMultiple){
        //     //   this.newAddressRep(this.remoteService.addressTypeIdMultiple[ati]); 
        //     // }  
        // }

        // this.validatingAddress('');
        this.newAddressRep(); 


        // START of previous ADDING MULTIPLE ADDRESSESS-----------
        // for(var ati in this.remoteService.addressTypeIdMultiple){
        //   console.log(this.remoteService.addressTypeIdMultiple[ati]);
        //   //this.remoteService.addressTypeIdMultiple = this.remoteService.address_type_id;
        //   if(this.remoteService.contact_country == "United States"){
        //     // setTimeout(()=>{
        //       this.remoteService.addressValidationCheck(this.remoteService.addressTypeIdMultiple[ati]).then((res: any)=>{
        //       setTimeout(()=>{  
        //           console.log(res)
                
        //         if (res == null) {
        //                     this.addOrEditAddress();
        //                     return
        //                   }
        //       // this.remoteService.addressValidationCheck(this.remoteService.addressTypeIdMultiple[ati]).subscribe((res: any)=>{
        //       this.addressValidationChecking(res,res['type']);
        //       // this.addressValidationChecking(res,this.remoteService.addressTypeIdMultiple[ati]);
        //       },200);
        //         // if(res.addrValidation == true){
        //         //   // if (this.remoteService.address_type_id=='3') {
        //         //   //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //         //   //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //         //   // }
        //         //   setTimeout(()=>{
        //         //     this.modelController.getTop().then(v => v ? this.modelController.dismiss('save'): null);
        //         //   },1500);
                  
        //         // }
        //       });
        //     // },500);
        //   }else{
        //     // if (this.remoteService.address_type_id=='3') {
        //     //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //     //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //     // }
        //     // this.remoteService.addNewAddress(this.remoteService.addressTypeIdMultiple[ati]);
        //     // setTimeout(()=>{
        //     //   this.modelController.getTop().then(v => v ? this.modelController.dismiss('save'): null);
        //     // },1500);

        //     this.newAddressRep(this.remoteService.addressTypeIdMultiple[ati])
            
        //   }
        // }
        // END of previous ADDING MULTIPLE ADDRESSESS---------


        // if(this.remoteService.contact_country == "United States"){
        //   this.remoteService.addressValidation(this.remoteService.address_type_id).then((res: any)=>{
        //     if(res.addrValidation == true){
        //       // if (this.remoteService.address_type_id=='3') {
        //       //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //       //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //       // }
        //       this.modelController.dismiss();
        //     }
        //   });
        // }else{
        //   // if (this.remoteService.address_type_id=='3') {
        //   //   this.remoteService.selectedShippingAddress.country = (this.remoteService.contact_country?this.remoteService.contact_country:this.remoteService.selectedShippingAddress.country?this.remoteService.selectedShippingAddress.country:'')
        //   //   this.remoteService.selectedShippingAddress.state = (this.remoteService.contact_state?this.remoteService.contact_state:this.remoteService.selectedShippingAddress.state?this.remoteService.selectedShippingAddress.state:'')
        //   // }
        //   this.remoteService.addNewAddress(this.remoteService.address_type_id);
        //   this.modelController.dismiss();
        // }
        // this.remoteService.addNewAddress(this.remoteService.address_type_id);
        // this.modelController.dismiss();
      }
    }
    
  }

  multiSelect(targt){
    console.log(this.remoteService.address_type_id);  
  }


  async validatingAddress(res){
    this.remoteService.showLoader();
    
    if(res){
                            for(var ati in this.remoteService.addressTypeIdMultiple){
                              await new Promise ( resolve => setTimeout(resolve,1500));
                              this.addressValidationChecking(res,this.remoteService.addressTypeIdMultiple[ati])
                            }



                          }else{
                            for(var ati in this.remoteService.addressTypeIdMultiple){
                              await new Promise ( resolve => setTimeout(resolve,1500));
                              // this.newAddressRep(this.remoteService.addressTypeIdMultiple[ati]); 
                            }  
                          }
            }
  // submitAddressForm(){
  //   this.remoteService.addNewAddress(this.remoteService.address_type_id);
  //   this.modelController.dismiss();
  // }

  // submitEditAddress(){
  //   //console.log("success in edit modal");
  //   this.remoteService.updateAddressDetails(this.remoteService.address_id);
  //   this.modelController.dismiss();
  // }

  countryClick(event){
    console.log(this.remoteService.contact_country);
    this.remoteService.contact_state="";
    this.remoteService.contact_city="";
    if(this.remoteService.contact_country != "United States"){
      $("#input_state").show();
      $("#input_city").show();
      $("#select_state").hide();
      $("#select_city").hide();
      this.showCityInputText = false;
      // this.registrationForm.addControl('stateText',this.fb.group({'', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]}));
      this.registrationForm.addControl('stateText', new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]));
      this.registrationForm.addControl('cityText', new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]));
      this.registrationForm.controls["stateText"].setValidators([Validators.required,Validators.minLength(2)]);
      this.registrationForm.controls["cityText"].setValidators([Validators.required,Validators.minLength(2)]);
    }else{
      this.remoteService.contact_state="California";
      this.remoteService.contact_city = "Sunnyvale";
      $("#input_state").hide();
      $("#input_city").hide();
      $("#select_state").show();
      $("#select_city").show();
      this.showCityInputText = true;
      // this.registrationForm.removeControl('stateText');
      // this.registrationForm.removeControl('cityText');
      this.registrationForm.controls["stateText"].setValidators([Validators.minLength(2)]);
      this.registrationForm.controls["cityText"].setValidators([Validators.minLength(2)]);
      this.registrationForm.controls["stateText"].updateValueAndValidity();
      this.registrationForm.controls["cityText"].updateValueAndValidity();
    }
  }

  stateClick(event){
    if(this.remoteService.contact_country == "United States" && (this.remoteService.contact_state == "California" || this.remoteService.contact_state == "CA")){
      //alert("working");
      this.remoteService.contact_city = "Sunnyvale";
      this.showCityInputText = true;
      $("#input_city").hide();
      $("#select_city").show();
      this.registrationForm.controls["cityText"].setValidators([Validators.minLength(2)]);
      this.registrationForm.controls["cityText"].updateValueAndValidity();
      // this.registrationForm.removeControl('cityText');
    }else{
      this.showCityInputText = false;
      this.remoteService.contact_city = null;
      $("#input_city").show();
      this.registrationForm.addControl('cityText', new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]));
      this.registrationForm.controls["cityText"].setValidators([Validators.required,Validators.minLength(2)]);
      $("#select_city").hide();
    }
  }

  addToAllAddress(event){
    //alert("all"+this.remoteService.add_to_all_address);
    if(this.remoteService.add_to_all_address != false){
      this.remoteService.addNewAddress(1);
      this.remoteService.addNewAddress(2);
      this.remoteService.addNewAddress(3);
    }
  }


  async openCountryList(option){
    // let routingData;
    // if (option=='country') {
    //   routingData = this.remoteService.country_list;
    // }else if(option=='state'){
    //   routingData = [];
    //   for(var rsl in this.remoteService.state_list){
    //     routingData.push(rsl)
    //   }
    // }else if(option=='city'){
    //   routingData = this.remoteService.city_list;
    // }

    const listModal = await this.modelController.create({component:AddressFieldsListPage,cssClass: 'fields-list',componentProps: {key: option},backdropDismiss:false});
    
    listModal.onDidDismiss().then((data) => {
      if (data.data && option=='country') {
        this.remoteService.contact_country = data.data;
        this.countryClick('');
      }else if(data.data && option=='state'){
        this.remoteService.contact_state = data.data;
        this.stateClick('');
      }else if(data.data && option=='city'){
        this.remoteService.contact_city = data.data;
      }
        
    });
    return listModal.present();
  }


  addressValidationChecking(data,addressId){
                        this.remoteService.validatedAddress = data.results[0][0][0];
                        var validadd1 = data.results[0][0][0][1];
                        var validcity = data.results[0][0][0][9];
                        var validstate = data.results[0][0][0][10];
                        var validzip = data.results[0][0][0][12];
                        data.addrValidation = true;
                        //console.log("this.validatedAddress", this.validatedAddress);
                        console.log(validadd1, validcity, validstate, validzip);
                        
                        if(validadd1 == "No Match Found" || validcity == "No Match Found" || validstate == "No Match Found" || validzip == "No Match Found"){
                          window.alert("Please fill the valid address, address validation failed");
                          // data.addrValidation = false;
                          return
                          // return resolve(data);
                        }
                        if(this.remoteService.contact_address1 != validadd1 || this.remoteService.contact_city != validcity || this.remoteService.contact_state != validstate || this.remoteService.contact_zipcode != validzip){
                          
                          if (!addressId) {
                            this.remoteService.contact_address1 = validadd1;
                            this.remoteService.contact_city = validcity ;    
                            // this.contact_state=validstate ;   
                            this.remoteService.contact_zipcode = validzip;
                          }

                          if (addressId) {
                            this.remoteService.addressEditPopup = false;
                          }
                          //Based on the button text validation the address is it Post or Put
                          if(this.remoteService.addressBtnText =="Save"){
                            //this.address_id = addressId;
                            //console.log("validated editaddress", this.address_id);
                            // this.remoteService.updateAddressDetails(addressId);
                            this.updateNewAddressDetailsRep(addressId);
                          }else{

                            if(this.remoteService.shippingAddressPopup){
                              this.remoteService.address_type_id = '3'
                            }
                            if (this.remoteService.billingAddressPopup){
                              this.remoteService.address_type_id = '2'
                            }
                            if (this.remoteService.designerAddressPopup){
                              this.remoteService.address_type_id = '1'
                            }


                            // this.newAddressRep(addressId)

                            
                          }
                          //this.addNewAddress(addressId);
                          //alert("post called");
                          // resolve(data);
                        }else{
                          if(this.remoteService.addressBtnText =="Save"){
                            //console.log("correct editaddress");

                            this.updateNewAddressDetailsRep(addressId);

                            // this.remoteService.updateAddressDetails(addressId);
                          }else{
                            //console.log("correct add address");
                            if(this.remoteService.shippingAddressPopup){
                              this.remoteService.address_type_id = '3'
                            }
                            if (this.remoteService.billingAddressPopup){
                              this.remoteService.address_type_id = '2'
                            }
                            if (this.remoteService.designerAddressPopup){
                              this.remoteService.address_type_id = '1'
                            }


                            // this.newAddressRep(addressId)
                          }
                          // resolve(data);
                        }
  }

  async newAddressRep(){
    // console.log(addressId)
    var address_data_body = {
      // "addressType": addressId, 
      "firstname":this.remoteService.contact_first_name,
      "lastname":this.remoteService.contact_last_name, 
      "email":this.remoteService.contact_email, 
      "phone":this.remoteService.contact_phone,
      "fax":this.remoteService.contact_fax,
      "address1": this.remoteService.contact_address1 , 
      "address2" : this.remoteService.contact_address2,
      "city":this.remoteService.contact_city ,
      "country":this.remoteService.contact_country, 
      "state":this.remoteService.contact_state,
      "zip":this.remoteService.contact_zipcode,
      // "defaultForType":this.remoteService.default_add_address,
      "company":this.remoteService.contact_company,
      "isDefaultForShipping": this.remoteService.address_type_id? (this.remoteService.address_type_id.join().indexOf('3')>-1? '1': '0'): '0',
      "isDefaultForBilling": this.remoteService.address_type_id? (this.remoteService.address_type_id.join().indexOf('2')>-1? '1': '0'): '0',
      "isDefaultForDesign": this.remoteService.address_type_id? (this.remoteService.address_type_id.join().indexOf('1')>-1? '1': '0'): '0'
    };

    // this.remoteService.address_type_id.join().indexOf('3')

    // for(var ids in this.remoteService.address_type_id){
    //   //console.log("validated add address");
    //   if (this.remoteService.address_type_id[ids] == '3') {
    //     address_data_body["isDefaultForShipping"] = '1';
    //   }else{
    //     address_data_body["isDefaultForShipping"] = '0';
    //   }

    //   if (this.remoteService.address_type_id[ids] == '2') {
    //     address_data_body["isDefaultForBilling"] = '1';
    //   }else{
    //     address_data_body["isDefaultForBilling"] = '0';
    //   }

    //   if (this.remoteService.address_type_id[ids] == '1') {
    //     address_data_body["isDefaultForDesign"] = '1';
    //   }else{
    //     address_data_body["isDefaultForDesign"] = '0';
    //   }
    // }
    // await new Promise(resolve => {
      // setTimeout(()=>{
        this.remoteService.addNewAddressNew('',address_data_body).then(()=>{
          this.newResponseAddress(address_data_body,'new');
        });
      // },3000);
    // });
  }

  newResponseAddress(address_data_body,path){
    let newAddressObj: any = {};
    let shippaddress: boolean = false;
    if (path == 'new') {
      newAddressObj = {
                                firstName        :address_data_body.firstname,
                                lastName         :address_data_body.lastname,
                                companyName      :address_data_body.company,
                                addressLine1     :address_data_body.address1,
                                addressLine2     :address_data_body.address2,
                                city             :address_data_body.city,
                                state            :address_data_body.state,
                                country          :address_data_body.country,
                                zip              :address_data_body.zip,
                                phoneNumber      :address_data_body.phone,
                                fax              :address_data_body.fax,
                                // default          :address_data_body.defaultForType,
                                //addressId        :address_data_body.addressId,
                                // addressTypeId    :address_data_body.addressType,
                                email            :address_data_body.email,
                                isDefaultForBilling :address_data_body.isDefaultForBilling,
                                isDefaultForDesign  :address_data_body.isDefaultForDesign,
                                isDefaultForShipping:address_data_body.isDefaultForShipping
                              }

      // if(address_data_body.defaultForType == 1 || address_data_body.defaultForType == '1'){
                                // if(address_data_body.addressType == 3 ){
                                //   this.remoteService.selectedShippingAddress=newAddressObj;
                                // }
                                // if(address_data_body.addressType == 2){
                                //   this.remoteService.selectedBillingAddress=newAddressObj;
                                // }
                                // if(address_data_body.addressType == 1 ){
                                //   this.remoteService.selectedDesignerAddress=newAddressObj;
                                // }

                                if(address_data_body.isDefaultForShipping == '1' || address_data_body.isDefaultForShipping == 1){
                                  this.remoteService.selectedShippingAddress = newAddressObj;
                                }
                                if(address_data_body.isDefaultForBilling == '1' || address_data_body.isDefaultForBilling == 1){
                                  this.remoteService.selectedBillingAddress = newAddressObj;
                                }
                                if(address_data_body.isDefaultForDesign == '1' || address_data_body.isDefaultForDesign == 1){
                                  this.remoteService.selectedDesignerAddress = newAddressObj;
                                }

                              // }
                              // this.saveAddressCount.push(newAddressObj)
                              // if (this.saveAddressCount.length == 1) {
                                this.remoteService.hideLoader();

                                // this.remoteService.getAddressDetails('list').then(()=>{});
                              // }
    //                           if (this.remoteService.addressTypeIdMultiple.length == this.saveAddressCount.length) {
    //                             // setTimeout(()=>{
      
    // // },2000);

    //     this.remoteService.getDisplayingAddress().then((data: any)=>{
    //       for(var i=0;i<data.length;i++){
    //         for(var j=0;j<this.saveAddressCount.length;j++){
    //           if (data[i]['firstname'] == this.saveAddressCount[j]['firstName'] && data[i]['lastname'] == this.saveAddressCount[j]['lastName'] && data[i]['address1'] == this.saveAddressCount[j]['addressLine1']&& data[i]['address2'] == this.saveAddressCount[j]['addressLine2'] && data[i]['city'] == this.saveAddressCount[j]['city'] && data[i]['state'] == this.saveAddressCount[j]['state'] && data[i]['country'] == this.saveAddressCount[j]['country'] && data[i]['zip'] == this.saveAddressCount[j]['zip'] && data[i]['phone'] == this.saveAddressCount[j]['phoneNumber'] && data[i]['email'] == this.saveAddressCount[j]['email']) {
    //             if(data[i]['addressType'] == 3 ){
    //               this.remoteService.selectedShippingAddress['addressId'] = data[i]['addressid'];
    //             }
    //             if(data[i]['addressType'] == 2){
    //               this.remoteService.selectedBillingAddress['addressId'] = data[i]['addressid'];
    //             }
    //             if(data[i]['addressType'] == 1 ){
    //               this.remoteService.selectedDesignerAddress['addressId'] = data[i]['addressid'];
    //             }
    //           }
    //         }
    //       }
    //     });
    //   }
    }else{
      
      if (address_data_body.isDefaultForShipping == '1') {
        shippaddress = true;
      }
                          // this.remoteService.getAddressDetails('list').then(()=>{});
      newAddressObj = {
                            firstName        :address_data_body.firstname,
                            lastName         :address_data_body.lastname,
                            addressLine1     :address_data_body.address1,
                            addressLine2     :address_data_body.address2,
                            city             :address_data_body.city,
                            state            :address_data_body.state,
                            country          :address_data_body.country,
                            zip              :address_data_body.zip,
                            phoneNumber      :address_data_body.phone,
                            fax              :address_data_body.fax, 
                            // default          :address_data_body.defaultForType,
                            addressId        :address_data_body.addressid,
                            // addressTypeId    :address_data_body.addressType,
                            email            :address_data_body.email,
                            companyName      :address_data_body.company,
                            isDefaultForBilling :address_data_body.isDefaultForBilling,
                            isDefaultForDesign  :address_data_body.isDefaultForDesign,
                            isDefaultForShipping:address_data_body.isDefaultForShipping
                          }



                          
                          if(address_data_body.isDefaultForShipping == '1' || address_data_body.isDefaultForShipping == 1 || address_data_body.isDefaultForShipping == "0"){
                            // this.remoteService.selectedShippingAddress = (newAddressObj.isDefaultForShipping == '1' || address_data_body.isDefaultForShipping == 1 )?newAddressObj:{};
                            this.remoteService.selectedShippingAddress = (newAddressObj.isDefaultForShipping == '1' || address_data_body.isDefaultForShipping == 1 )?newAddressObj:this.remoteService.selectedShippingAddress;
                          }
                          if(address_data_body.isDefaultForBilling == '1' || address_data_body.isDefaultForBilling == 1 || address_data_body.isDefaultForBilling == "0"){
                            // this.remoteService.selectedBillingAddress = (newAddressObj.isDefaultForBilling == '1' || address_data_body.isDefaultForBilling == 1 )?newAddressObj:{};
                            this.remoteService.selectedBillingAddress = (newAddressObj.isDefaultForBilling == '1' || address_data_body.isDefaultForBilling == 1 )?newAddressObj:this.remoteService.selectedBillingAddress;
                          }
                          if(address_data_body.isDefaultForDesign == '1' || address_data_body.isDefaultForDesign == 1 || address_data_body.isDefaultForDesign == "0"){
                            // this.remoteService.selectedDesignerAddress = (newAddressObj.isDefaultForDesign == '1' || address_data_body.isDefaultForDesign == 1 )?newAddressObj:{};
                            this.remoteService.selectedDesignerAddress = (newAddressObj.isDefaultForDesign == '1' || address_data_body.isDefaultForDesign == 1 )?newAddressObj:this.remoteService.selectedDesignerAddress;
                          }
    
                              // if((this.remoteService.address_id == this.remoteService.selectedShippingAddress['addressId']) || (this.remoteService.address_id == this.remoteService.selectedBillingAddress['addressId']) || (this.remoteService.address_id == this.remoteService.selectedDesignerAddress['addressId'])){
                                // if(address_data_body.addressType == 3 ){
                                //   this.remoteService.selectedShippingAddress = newAddressObj;
                                // }
                                // if(address_data_body.addressType == 2){
                                //   this.remoteService.selectedBillingAddress = newAddressObj;
                                // }
                                // if(address_data_body.addressType == 1 ){
                                //   this.remoteService.selectedDesignerAddress = newAddressObj;
                                // }

                              // }
                              // else if(newAddressObj['default'] == '1' || newAddressObj['default'] == 1){
                              //   if(address_data_body.addressType == 3 ){
                              //     this.remoteService.selectedShippingAddress=newAddressObj;
                              //   }
                              //   if(address_data_body.addressType == 2){
                              //     this.remoteService.selectedBillingAddress=newAddressObj;
                              //   }
                              //   if(address_data_body.addressType == 1 ){
                              //     this.remoteService.selectedDesignerAddress=newAddressObj;
                              //   }
                              // }
    }
      // this.modelController.dismiss('save')

      this.remoteService.getAddressDetails('list').then(()=>{
      this.closeModal(path);

        this.modelController.getTop().then(v => v ? this.modelController.dismiss({'status': 'save','type': shippaddress ?address_data_body:''}): null);
        
      });
      // setTimeout(()=>{
        // this.modelController.getTop().then(v => v ? this.modelController.dismiss({'status': 'save','type': this.remoteService.selectedShippingAddress}): null);
        // this.modelController.getTop().then(v => v ? this.modelController.dismiss({'status': 'save','type': shippaddress ?address_data_body:''}): null);
        // this.modelController.getTop().then(v => v ? this.modelController.dismiss({'status': 'save','type': (this.remoteService.selectedShippingAddress.isDefaultForShipping == '1' && (this.remoteService.shippingAddressPopup || this.remoteService.billingAddressPopup || this.remoteService.designerAddressPopup) ?address_data_body:'')}): null);
      // this.modelController.getTop().then(v => v ? this.modelController.dismiss('save'): null);
    // },500);
  }




  updateNewAddressDetailsRep(addressId){
    // var default_add = this.remoteService.default_add_address == true ? "1":"0";
    // var update_address_body = {"addressType":this.remoteService.address_type_id,"addressid":addressId, "firstname":this.remoteService.contact_first_name,
    //                             "lastname":this.remoteService.contact_last_name, "email":this.remoteService.contact_email, "phone":this.remoteService.contact_phone,"fax":this.remoteService.contact_fax,
    //                             "address1": this.remoteService.contact_address1 , "address2" : this.remoteService.contact_address2,
    //                             "city":this.remoteService.contact_city ,"country":this.remoteService.contact_country, "state":this.remoteService.contact_state,
    //                             "zip":this.remoteService.contact_zipcode,"defaultForType":default_add,"company":this.remoteService.contact_company};
                            //console.log("validated add address");
    this.remoteService.updateNewAddressDetails(addressId).subscribe((data)=>{
      if(data != 'Access denied'){
        this.newResponseAddress(data,'update');
      }else{
        this.router.navigateByUrl('/session-timeout-model');
      }
    });
  }

}
